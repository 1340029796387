body .home-blockquotes{
  width: 90%;
  position: relative !important;
  margin: calc(20vh/2) auto;
  overflow: hidden;
  border: 2px solid var(--color-neutral-0);
  border-radius: var(--border-radius-soft);
  box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
  background-color: var(--color-neutral-0);
}

.slides{
  width: 100%;
  height: 100%;
  display: flex;
}

.slide{
  min-width: 100%;
  height: 100%;
  position: relative;
  padding: var(--space-l);
}


.slider-section {
  padding: var(--space-xxl);
  text-align: left;
}

.slider-section__title {
  color: var(--color-primary);
  font-size: var(--font-size-xl);
}

.left-quote {
  position: absolute;
  left: 0;
  top: -50px;
}

.right-quote {
  position: absolute;
  right: 0;
  bottom: -50px;
}

.position-relative {
  position: relative;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  padding: var(--space-l);
  font-size: 18px;
  background: #fff;
}

.swiper-slide p:first-child {
  color: var(--color-neutral-11);
}

.swiper-pagination-bullet {
  background-color: transparent;
  width: 10px;
  height: 10px;
  margin: var(--space-xs);
  border: 1px solid var(--color-blue-darkest-1);
}

body .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.swiper-pagination {
  position: absolute;
  left: 0;
  right: 0;
}