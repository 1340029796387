// .button {
//   font-size: 1rem;
//   letter-spacing: 0.04em;
//   text-decoration: none;
//   // text-transform: uppercase;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   position: relative;
//   min-width: 88px;
//   height: 36px;
//   padding: 0 16px;
//   text-align: center;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   -webkit-appearance: none;
//   -webkit-tap-highlight-color: transparent;
//   overflow: hidden;
//   vertical-align: middle;
//   border-radius: 2em;
//   border: 2px solid;
//   color: $color-grey-800;
//   font-weight: 400;
//   outline: none;

//   &:hover {
//     text-decoration: none;
//   }

//   &--shadow {
//     box-shadow: 0 0 15px 2px #ccc;
//   }
// }

// .button--color-grey {
//   $blob-color: #c1c1c1;
//   color: rgba(#6f6f6e, 0.8);
//   &:hover {
//     border-color: $blob-color;
//     color: #333;
//   }
//   .blob-btn__blob {
//     background: $blob-color;
//   }
// }

// .button--light {
//   $blob-color: #b9b9b9;
//   color: rgba(#a9a9a9, 0.8);
//   &:hover {
//     border-color: $blob-color;
//     color: #333;
//   }
//   .blob-btn__blob {
//     background: $blob-color;
//   }
// }

// .button--color-white {
//   $blob-color: #179ee6;
//   color: #fff;

//   &:hover {
//     color: #fff;
//     border-color: $blob-color;
//   }

//   .blob-btn__blob {
//     background: $blob-color;
//   }

//   .button-icon {
//     fill: #fff;
//   }
// }

// .button--primary {
//   $blob-color: #f0edf1;
//   color: #fff;
//   .blob-btn__inner {
//     background-color: $color-primary;
//     border-color: $color-primary;
//     border-radius: 2em;
//   }
//   .blob-btn__blob {
//     background: $blob-color;
//   }
//   .button-icon {
//     fill: #fff;
//     @include transition();
//   }
//   &:hover {
//     color: #000;
//     border-color: $blob-color;
//     .button-icon {
//       fill: #000;
//     }
//   }
// }

// .button--blue {
//   $blob-color: #f0edf1;
//   color: #fff;
//   .blob-btn__inner {
//     background-color: $color-primary-dark;
//     border-color: $color-primary-dark;
//     border-radius: 2em;
//   }
//   .blob-btn__blob {
//     background: $blob-color;
//   }
//   .button-icon {
//     fill: #fff;
//     @include transition();
//   }
//   &:hover {
//     color: #000;
//     border-color: $blob-color;
//     .button-icon {
//       fill: #000;
//     }
//   }
// }

// .button--secondary {
//   $blob-color: #ffd548;
//   background-color: $color-secondary;
//   border-color: $color-secondary;
//   color: #000;
//   &:hover {
//     color: #000;
//     border-color: $blob-color;
//   }
//   .blob-btn__blob {
//     background: $blob-color;
//   }
//   .button-icon {
//     fill: #000;
//   }
// }

// .button--size-md {
//   min-width: rem(120);
//   padding-left: rem(25);
//   padding-right: rem(25);
//   height: 42px;
// }

// .button--size-lg {
//   min-width: rem(150);
//   padding-left: rem(35);
//   padding-right: rem(35);
//   height: 50px;
// }

// .button-rounded {
//   border-radius: 50%;
//   min-width: 10px;
//   .blob-btn__blob {
//     width: 100%;
//   }
// }
// .button-icon {
//   margin-#{$start}: 16px;
// }
// .button__submit--secondary {
//   cursor: pointer;
//   background-color: $color-secondary;
//   border-color: $color-secondary;
//   color: #000;
//   justify-content: center;
// }

// $cyan: #0fe0f5;
// $dark: #222;
// $blob-color: #006398;
// $borderW: 2px;

// .blob-btn {
//   $numOfBlobs: 3;
//   z-index: 1;
//   // position: relative;
//   // padding: 20px 46px;
//   // color: $cyan;
//   // background-color: transparent;
//   // outline: none;
//   // border: none;
//   cursor: pointer;
//   @include transition();

//   &:before {
//     content: "";
//     z-index: 1;
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     // border: $borderW solid $cyan;
//   }

//   &:hover {
//     // color: $dark;
//     // border-color: transparent;
//     &:after {
//       transition: all 0.3s;
//       left: 0;
//       top: 0;
//     }
//   }

//   &__inner {
//     z-index: -1;
//     overflow: hidden;
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//   }

//   // additional container created, because in FF blobs are breaking overflow:hidden of element with svg gooey filter
//   &__blobs {
//     position: relative;
//     display: block;
//     height: 100%;
//     // filter: url('#goo');
//   }

//   &__blob {
//     position: absolute;
//     top: $borderW;
//     width: 100% / $numOfBlobs;
//     height: 100%;
//     // background: $blob-color;
//     border-radius: 100%;
//     transition: transform 0.45s;
//     transform: translate3d(0, 150%, 0) scale(1.7);
//     // @supports(filter: url('#goo')) {
//     //   transform: translate3d(0,150%,0) scale(1.4);
//     // }

//     @for $i from 1 through $numOfBlobs {
//       &:nth-child(#{$i}) {
//         left: ($i - 1) * (120% / $numOfBlobs);
//         transition-delay: ($i - 1) * 0.08s;
//       }
//     }

//     .blob-btn:hover & {
//       transform: translateZ(0) scale(1.7);
//       // @supports(filter: url('#goo')) {
//       //   transform: translateZ(0) scale(1.4);
//       // }
//     }
//   }
// }
// .button-circle {
//   width: 50px;
//   height: 50px;
// }

// .input-round {
//   border: none;
//   border: 1px solid #ddd;
//   border-radius: 50px;
//   padding: 12px 25px;
//   outline: none;
//   min-width: 350px;
// }


.button {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  letter-spacing: 0.04em;
  text-decoration: none;
  // text-transform: uppercase;
  display: inline-flex;
  position: relative;
  padding: var(--space-s) var(--space-base);
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  vertical-align: middle;
  border: 2px solid;
  color: var(--color-primary);
  font-weight: 400;
  outline: none;
  &:focus {
    border: 1px solid var(--color-primary);
  }
}

// light button

.button--light {
  background-color: var(--color-white-3);
  color: var(---color-black-0);
  &:hover {
    background-color: var(--color-primary);
   color: var(--color-neutral-0);
  }
}

// primary button

.button--primary {
  background-color: var(--color-primary);
  color: var(--color-neutral-0);
  
  &:hover {
    background-color: var(--color-primary-hover);
    color: var(--color-neutral-0);
    transition: 0.3s;
  }
}


// secondary button

.button--secondary {
  background-color: var(--color-secondary);
  color: var(--color-neutral-0);
  &:hover {
    background-color: var(--color-neutral-8);
    color: var(--color-secondary);
    transition: 0.3s;
  }
}

// button icon 

.button--icon {
  background-color: var(--color-primary);
  color: var(--color-neutral-0);
  &:hover {
    background-color: var(--color-primary);
    color: var(--color-neutral-0);
    transition: 0.3s;
  }
}

// outline button 

.button--outline {
  background-color: var(--color-white-0);
  color: var(---color-black-0);
  border: 1px solid;
  &:hover {
    background-color: var(--color-primary);
    color: var(--color-neutral-0);
    transition: 0.3s;
  }
}

// button raised 

.button--raised {
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

// round button 

.button--rounded {
  border-radius: var(--border-radius-rounded);
}


// large button

.button--lg {
  padding-top: var(--space-m);
  padding-bottom: var(--space-m);
  padding-left: var(--space-l);
  padding-right: var(--space-l);
}

// blcok button

.button--block {
  width: 100%;
}

.button--transparent-background {
  background-color: transparent;
}

.material-icons {
  color: var(--color-neutral-0);
}