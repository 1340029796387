.icons-bar{
    display: flex;
}

.icon {
    display: inline-flex;
    padding: 8px;
    border-radius: 50%;
    background-color: var(--color-shade-0);
    @include transition();
    > svg {
        fill: rgba(var(--color-shade-0), .54);
        width: 24px;
        height: 24px;
    }

    &--sm{
        > svg{
            width: 16px;
            height: 16px;
        }
    }

    &:hover {
        background-color: var(--color-neutral-6);
    }
}
