.white-title-section{
    color: #fff;
}
.accordion-section {
	width: 100%;
	margin-top: 20px;
	.accordion__item {
		transition: 0.4s;
		border-top: 1px solid #eeeeee;
		border-bottom: 1px solid #eeeeee;
		padding: 25px 0 25px 30px;
		&.active {
			background: #e9f4fd;
		}
		.accordion__item--title {
			background: transparent;
			margin: 0;
			text-align: left;
			cursor: pointer;
			color: #505050;
			position: relative;
			&.active {
				color: #92298d;
				&::after {
					content: "\f068";
				}
			}
			&::after {
				content: "\f067";
				font-family: "fontAwesome";
				position: absolute;
				right: 5px;
			}
		}
		.accordion__item--description {
			transition: 0.4s;
			text-align: left;
			display: none;
			overflow: hidden;
			margin-top: 30px;
			line-height: 30px;
		}
	}
}
