.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include breakpoint(small){
    --image-height: 200px;
  }
  &--border{
    border: var(--border-size-s) solid var(--color-neutral-5); 
  }
  &--border-soft{
    border-radius: var(--border-radius-soft);
    .card__image{
      img{
        border-top-left-radius: var(--border-radius-soft);
        border-top-right-radius: var(--border-radius-soft);
      }
    }
  }
  &--elevation{
    box-shadow: var(--shadow-s);
  }
}
.card__image{
  height: var(--image-height);
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.card__content{
  padding: var(--space-base);
}
.card__title{
  font-size: 1.3em;
  color: var(--color-neutral-10);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  a{
    text-decoration: none;
    color: var(--color-violet-darkest-1);
    transition: color .3s ease-in-out;
    &:hover{
      color: var(--color-neutral-10);
    }
  }
}
.card__brief{
  &--lines-3{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
}
.card__actions{
  padding-left: var(--space-base);
  padding-right: var(--space-base);
  padding-bottom: var(--space-base);
  margin-top: auto;
}
.card--horizontal{
  flex-direction: row;
  .card__image{
    flex: 0 0 40%;
  }
  .card__actions{
    padding: var(--space-l) var(--space-none) var(--space-none) var(--space-none);
  }
}
.card--reverse{
  flex-direction: row-reverse;
}

.card__image--absolute {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  bottom: 24px;
  left: -5px;
  max-width: 305px;
  text-align: start;
  min-width: 305px;
}

.card__title .color-white {
  color: var(--color-neutral-0);
}

.card .card__image--350 {
  height: 350px;
}

@media only screen and (max-width: 480px) {
  .bottom-spacing {
    margin-bottom: 20px;
  }
}

.card__icon::before {
  content: '';
   display: block;
   margin-bottom: var(--space-s);
   width: 24px;
   height: 24px;
   border-radius: 50%;
   background-image: linear-gradient(90deg, rgb(111, 203, 220) 0.23%, rgb(236, 15, 104) 23%, rgb(191, 62, 150) 49%, rgb(237, 37, 41) 75%, rgb(241, 103, 34) 94%);
  }


  .page-section {
    padding: var(--space-xl) 0;
    text-align: center;
  }

  .page-section__title {
    font-size: var(--font-size-xl);
    color: var(--color-primary);
    margin-bottom: var(--space-s);
  }

  .page-section__decription {
    color: var(--color-gray-darkest-1);
    margin-bottom: var(--space-xxl);
  }

  .card__image--rounded {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    margin: auto;
    position: relative;
  }

  .card__image--rounded svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }


  .h-100 {
    height: 100%;
  }

  .card__title .color-purple {
    color: var(--color-primary);
  }

  .card__title .color-pink2 {
    color: var(--color-pink-1);
  }

  .card__title .font-size-xxl {
    font-size: var(--font-size-xxl);
  }

  .text-center {
    text-align: center;
  }

  .m-auto {
    margin: auto;
  }

  .card .padding-left-none {
    padding-left: var(--space-none);
  }
  
  @media only screen and (max-width: 768px) {
    .card--horizontal {
      display: block;
    }
  }

  .card__image img {
    min-height: 660px;
  }


  .card-background-right {
    background-image: url(/src/images/Group728.jpg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-color: var(--color-gray-light);
  }

  .text-start {
    text-align: start;
  }

  @media only screen and (max-width: 1024px) {
    .card-background-right {
      background-image: none;
    }
  }

  body .card-image--left img {
    min-height: auto;
    width: 100%;
  }

  body .card__content--right {
    text-align: start;
    padding-top: var(--space-xl);
  }

  body .card-image--right img {
    min-height: auto;
    width: 100%;
  }

  body .card__content--left {
    text-align: end;
    padding-top: var(--space-xl);
  }

  @media only screen and (min-width: 769px){
    body .card__content--right {
      text-align: start;
      padding-left: var(--space-xxl);
      padding-top: var(--space-none);
    }

    body .card__content--left {
      text-align: end;
      padding-right: var(--space-xxl);
      padding-top: var(--space-none);
    }
  }