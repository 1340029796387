﻿.gallery-section {
    padding: var(--space-xxl) 0;
    text-align: center;
}

.gallery-section__title {
    font-size: var(--font-size-xl);
    color: var(--color-primary);
}

.gallery-section__description {
    color: var(--color-gray-darkest-1);
    margin-bottom: var(--space-l);
}

.padding-left-none {
    padding-left: var(--space-none);
}

.gallery-grid-container {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1.1fr 1.1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 30px 30px;
    grid-template-areas:
      "image-1 image-2 image-2 image-2 image-2"
      "image-1 image-3 image-3 image-4 image-4"
      "image-5 image-5 image-5 image-4 image-4"
      "image-5 image-5 image-5 image-6 image-6";
  }
  
  .image-1 { grid-area: image-1; }
  
  .image-2 { grid-area: image-2; }
  
  .image-3 { grid-area: image-3; }
  
  .image-4 { grid-area: image-4; }
  
  .image-5 { grid-area: image-5; }
  
  .image-6 { grid-area: image-6; }

  .image-1 img { 
      width: 100%;
      height: 100%;
   }
  
  .image-2 img { 
      width: 100%;
      height: 100%;
   }
  
  .image-3 img { 
      width: 100%;
      height: 100%;
   }
  
  .image-4 img { 
      width: 100%;
      height: 100%;
   }
  
  .image-5 img { 
      width: 100%;
      height: 100%;
  }
  .image-6 img { 
      width: 100%;
      height: 100%;
   }