:root {
    // Typography - Size 
    --font-size-h1: 2rem;
    --font-size-h2: 1.75rem;
    --font-size-h3: 1.625rem;
    --font-size-h4: 1.375rem;
    --font-size-h5: 1.25rem;
    --font-size-h6: 1.125rem;
    --font-size-xxl: 3rem;
    --font-size-xl: 2.5rem;
    --font-size-mx: 2.25rem;
    --font-size-base: 1rem;
    --font-size-s: .85rem;
    --font-size-xs: .7rem;
    
    

    --font-code: monospace;

    // Elevation / Shadow 
    --shadow-none: none;
    --shadow-xs: 0 1px 2px rgba(0, 0, 0, .1);
    --shadow-s: 0 2px 4px rgba(0, 0, 0, .1);
    --shadow-m: 0 4px 6px rgba(0, 0, 0, .1);
    --shadow-l: 0 6px 8px rgba(0, 0, 0, .1);
    --shadow-xl: 0 8px 10px rgba(0, 0, 0, .1);
    
    // App Settings 
    --header-color: #ffffff;
    --header-size: 56px;
    --header-size-content: 48px;
    --side-menu-size: 300px;
    --bottom-bar-size: 56px;

    --gutter-width: 16px;
    --container-box-margin: 113px;
    
    // iOS Safe Areas (Notch Support) 
    --os-safe-area-top: env(safe-area-inset-top);
    --os-safe-area-right: env(safe-area-inset-right);
    --os-safe-area-bottom: env(safe-area-inset-bottom);
    --os-safe-area-left: env(safe-area-inset-left);

    --brand-width: 5.35rem;
    --brand-height: 2.3rem;
}
$font-weights:(
    "light": 300,
    "regular": 400,
    "semi-bold": 500,
    "bold": 700,
);



$sizes: (
    "auto": auto,
    "none": 0,        // 0,
    "xs": .25rem,     // 4px,
    "s": .5rem,       // 8px,
    "base": 1rem,     // 16px,
    "m": 1.5rem,      // 24px,
    "l": 2rem,        // 32px,
    "xl": 2.5rem,     // 40px,
    "xxl": 3rem,      // 48px,
);

$border-radius: (
    "none": 0,
    "soft": 5px,
    "rounded": 1em,
    "circle": 50%,
);

$border-sizes: (
    "none": 0,
    "base": 1px,
    "m": 2px,
    "l": 3px,
    "xl": 6px,
);

$directions: (
    "top": top, 
    "bottom": bottom, 
    "start": inline-start, 
    "end": inline-end,
    "right": right,
    "left": left,
    "y": '',
    "x": ''
);


//== images path
$img-path: "../images";
$font-path: "../fonts";

$screen-xs: 0 !default;
$screen-sm: 576px !default;
$screen-md: 768px !default;
$screen-lg: 992px !default;
$screen-xl: 1367px !default;
$screen-wd: 1600px !default;

$breakpoints: (xs: 480px, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1600px, xlst: 1920px);
$max-breakpoints: (xs: 479px, sm: 575px, md: 767px, lg: 991px, xl: 1199px, xxl: 1599px);

$youtubeClr: #bf232b;
$twitterClr: #2aaae0;
$facebookClr: #3a589b;
$linkedinClr: #0e76a8;
$whatsappClr: #0dc143;
$instagramGradient: linear-gradient( 40deg, rgb(249,237,50) 0%, rgb(238,42,123) 69%, rgb(0,42,255) 100%);