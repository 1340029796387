:root{
    --font-family: "Rubik", sans-serif;
     
    // Header
     --header-bg: #fff;
     --footer-bg: #fff;

     // Color - Navbar
     --color-navbar: var(--color-black-1);
     --color-navbar--hover: var(--color-neutral-5);
}

$colors: (
    "primary": "#92298D",
    "primary-darken": "#BF3E96",
    "primary-hover": "#be35b7",
    "primary-selected": "rgba(20, 110, 245, .12)",
    "primary-lightest": "linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9))",

    "secondary": "#fff",
    "secondary-darken": "#6FCBDC",

    "orange-0": "#F16722",
    "orange-1": "#ED2529",

    "light-blue-0": "#3FD4F0",

    "green-0": "#32A071",

    "white-0": "#FFFFFF",
    "white-1": "#FAFAFA",
    "white-2": "#F3F3F3",
    "white-3": "#F7FAFA",

    "black-0": "#000",
    "black-1": "#50555C",
    "black-2": "#616161",
    "black-3": "#F9F9F9",

    "pink-1": "#D3157A",
    
    "side-nav-text": "#425166",
    "dashboard-1": "#14A99B",
    "dashboard-2": "#ED6612",
    "dashboard-3": "#7D30CB",
    "dashboard-4": "#A02A58",
    "dashboard-5": "#004977",
    "dashboard-6": "#425166",
    "dashboard-7": "#25C97E",
    "dashboard-8" :'#0D7F97'
   
);

@include colors-variation($colors);