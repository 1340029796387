.terms-section-title{
    color: #D3157A;
}
.terms-main-title{
    font-size: var(--font-size-xxl);
    color: var(--color-primary);;
}
.terms-section{
    text-align: left;
    h2{
        color: #1C0F5F;
        font-size: var(--font-size-h2);
        font-weight: 500;
    }
    h3{
        color: #505050;
        font-weight: 700;
        font-size: var(--font-size-h5);
    }
    p{
        font-size: var(--font-size-h5);
        color: #505050;
        line-height: var(--font-size-mx);
        margin-bottom: 30px;
    }
    hr{
        border-top: 1px solid #eee;
    }
    ul{
        font-size: var(--font-size-h5);
        color: #505050;
        line-height: var(--font-size-mx);
        list-style: none;
        padding: 0;
        margin-bottom: 30px;
    }
}