.navbar {
  padding: 0;
  margin: 0;
  list-style: none;
  @include breakpoint(mid){
    display: flex;
    margin: 0 calc(-1 * var(--space-base));
  }
}
.navbar__item {
  display: flex;
  position: relative;
}
.navbar__link {
  color: var(--color-neutral-0);
  font-weight: var(--font-regular);
  transition: color 0.3s ease-in-out;
  padding: var(--space-xs) var(--space-m);
  @include breakpoint(isCommon){
    display: flex;
    width: 100%;
    padding: var(--space-base) var(--space-s);
    border-bottom: 1px solid var(--color-neutral-3);
  }
  &:hover{
    color: var(--color-pink-1);
    text-decoration: none;
  }
}


.global-navigation{
  @media only screen and (max-width: 600px){
    opacity: 0;
    visibility: hidden;
    position: fixed;
    background: var(--color-shade-0);
    padding: var(--space-base);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform: scale(.98);
    transition: all .3s ease-in-out;
  }
  &--opened{
    @include breakpoint(isCommon){
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
}

.global-navigation__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-l);
  @include breakpoint(mid){
    display: none;
  }
}

.navbar__group-button {
  display: flex;
}

@media only sreen and (min-width: 600px) {
  .navbar__group-button {
    margin-left: var(--space-xxl);
  }
}

@media (max-width: 600px) {
  .navbar__group-button {
    margin-top: var(--space-m);
  }
}

.navbar__item__register {
  border: 1px solid;
}

.ar-font {
  font-family: 'Tajawal', sans-serif;
}