.contact-section {
	text-align: center;
	width: 100%;
	.main-title {
		font-size: var(--font-size-mx);
		color: #1c0f5f;
		font-weight: 500;
		margin: var(--space-l);
	}
	.contact-details {
		p {
			color: #505050;
			font-size: var(--font-size-h5);
			margin: 0;
			line-height: 40px;
		}
	}
	+ hr {
		border-top: 1px solid #eee;
		width: 100%;
		margin: var(--space-xl);
	}
}

.contact-form {
	width: 65%;
	text-align: center;
	margin: auto;
	p {
		font-size: var(--font-size-h2);
		line-height: var(--font-size-xl);
		color: #505050;
        margin-bottom: var(--space-xl);
	}
    .form-field{
        margin-bottom: var(--space-l);
        input , textarea{
            border:0;
            border-bottom: 1px solid #c4c4c4;
            width: 100%;
            line-height: var(--font-size-xl);
            outline: none;
        }
    }

}

@media only screen and (max-width: 600px) {
	.contact-form {
		width: 90%;
	}
}
