.header {
  padding: 1.8rem 0;
  z-index: 100;
  position: absolute;
  width: 100%;
  // transition: transform .4s ease-in-out;
  @include breakpoint(mid){
    // position: fixed;
  }
  h1 {
    margin-bottom: 0;
  }

  @include breakpoint(isMobile) {
    background-color: #fff;
    padding: 5px 0;
  }

  .change-language {
    margin-#{$start}: 25px;
  }

  &--absolute {
    @include breakpoint(mid) {
      position: absolute;
      width: 100%;
      z-index: 10;
    }
  }
  &--relative{
    position: relative;
  }
}

.header__button {
  border-width: 2px;
}

.header__search-box {
  margin-#{$end}: 15px;
  display: flex;
  align-items: center;
}

.header__search-txtbox {
  background: transparent;
  border: 0;
  outline: none;
  color: #fff;
  margin-#{$start}: 5px;
  width: 70px;
  transition: all 0.3s ease-in-out 0s;

  &:focus {
    width: 200px;
  }

  &::placeholder {
    color: rgba(#fff, 0.87);
  }
}

.responsive-menu {
  display: flex;
  flex-direction: inherit;
  align-items: center;

  @include breakpoint(isMobile) {
    display: none;
    flex-direction: column-reverse;
  }

  > nav {
    @include breakpoint(isMobile) {
      margin-bottom: auto;
    }
  }
}

.hamburger-menu {
  position: absolute;
  right: auto;
  top: auto;
  z-index: 100;
  width: 20px;
  height: auto;
  // background: #FFF;
  border-radius: 50% 50% 50% 50%;
  padding: 10px;
  transition: all 0.3s ease-in-out 0s;
  // box-shadow: 0 0 0 0 #FFF, 0 0 0 0 #FFF;
  cursor: pointer;
  display: none;

  @include breakpoint(isMobile) {
    display: block;
  }
}

.hamburger-menu > span {
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  height: 2px;
  background: #000;
  display: block;
  transform-origin: center;
  transition: 0.5s ease-in-out;

  @include breakpoint(mid) {
    width: 30px;
    top: 150px;
    left: 50px;
  }

  &:after,
  &:before {
    transition: all 0.3s ease-in-out 0s;
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: #000;
    transition: 0.5s ease-in-out;
  }

  &:before {
    top: -6px;

    @include breakpoint(mid) {
      top: -10px;
    }
  }

  &:after {
    bottom: -6px;

    @include breakpoint(mid) {
      bottom: -10px;
    }
  }
}

.hamburger-menu--dark {
  > span {
    background-color: #000;
    &:after,
    &:before {
      background-color: #000;
    }
  }
}

.hamburger-menu--open {
  box-shadow: 0 0 0 100vw #fff, 0 0 0 100vh #fff;
  background: #fff;
  border-radius: 0;
  position: fixed;

  > span {
    transform: rotate(45deg);
    background: #000;

    &:after {
      transform: rotate(90deg);
      bottom: 0;
      background: #000;
    }

    &:before {
      transform: rotate(90deg);
      top: 0;
      background: #000;
    }
  }
}

.responsive-menu--open {
  display: flex;
  position: fixed;
  top: 100px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  z-index: 100;
  min-height: calc(100vh - 200px);

  .navbar {
    flex-direction: column;
  }

  .navbar__item {
    color: rgba(#000, 0.87);
    text-align: center;
    display: block;
    font-size: 1.9em;
    padding: 5px 25px;
    @include breakpoint(isCommon){
      text-align: left;
    }
  }
}

.header--fixed {
  position: fixed;
  background-color: #fff;
  //padding: 10px;
  //font-size: 14px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
  z-index: 20;

  @include breakpoint(mid) {
    padding: 10px;
    font-size: 14px;
  }

  .container {
    max-width: 1500px;
    transition: all 0.5s ease-in-out 0s;
  }

  .brand {
    width: 120px;
  }

  .brand__emblem {
    // fill: $color-primary;
  }

  .brand__text {
    fill: #424242;
  }

  .navbar__item:not(.navbar__item--current) {
    color: rgba(#000, 0.54);
  }

  .button--color-white {
    // .blob-btn__inner {
    //     background-color: $color-primary;
    //     border-color: $color-primary;
    // }
  }
}

.Lang-btn{
  color: #fff;
  padding: 0 25px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 20px;
    right: 100%;
    height: 3px;
    background: #ffc400;
    bottom: -10px;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
      @include breakpoint(mid) {
        text-decoration: none;
        // color: $color-secondary;
        &::after{
          right: 20px;
        }
    }
  }
}

.body--relative-header {
  .header {
    position: relative;
    padding: 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .brand {
    width: 130px;
  }

  .brand__emblem {
    fill: #37a2dc;
  }

  .brand__text {
    fill: #424242;
  }
}

.body--hide-menu {
  .hamburger-menu,
  .responsive-menu {
    display: none;
  }
}

@include breakpoint(mid){
  .header--scrolling{
    background-color: #fff;
    padding: .5rem 0;
    box-shadow: 0 0 10px 3px rgba(0,0,0,.2);
    z-index: 100;
    position: fixed;
    transform: translate(0, -100%);
    @include transition();
    .container{
      max-width: 95%;
      // @include transition();
    }
    .brand{
      width: 140px;
    }
    .brand__emblem {
      // fill: $color-primary;
    }
    .brand__text {
      fill: #424242;
    }
    .navbar__item{
      color: rgba(#000, 0.87);
    }
    .navbar{
      font-size: 14px;
    }
  }
  
}

.body--scrolling-down{
  .header{
    transform: translate(0, -64px);
  }
  .price__table--fixed{
    transform: translate(0, 0);
  }
}
.body--scrolling-up{
  .header{
    transform: translate(0, 0);
  }
  .price__table--fixed{
    @include breakpoint(mid){
      transform: translate(0, 64px);
    }
  }
}

body .main-header--transparent-background {
  background-color: transparent;
}

.border-none {
  border: none;
}

.border--primary {
  border-color: var(--color-primary);
}

.navbar__item__login {
  margin-right: var(--space-base);
}

.burger-menu--light {
  color: var(--color-neutral-0);
}