﻿.main-footer__nav-link {
    color: var(--color-gray-darkest-4);
}

.footer--brand {
    width: 126px;
    height: 55px;
    margin-bottom: var(--space-l);
}

.main-footer__nav--end {
    justify-content: flex-end;
}

@media only screen and (min-width: 600px) {
    .main-footer__bottom-text {
        display: flex;
        justify-content: space-between;
    }
}

.main-footer__bottom-text {
    text-align: center;
}

hr {
    border: 1px solid var(--color-light-gray);
}

.main-footer__container {
    align-items: center;
}

.main-footer__copy-rights {
    color: var(--color-neutral-11);
}