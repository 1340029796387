:root {
  --font-size-h1: 2rem;
  --font-size-h2: 1.75rem;
  --font-size-h3: 1.625rem;
  --font-size-h4: 1.375rem;
  --font-size-h5: 1.25rem;
  --font-size-h6: 1.125rem;
  --font-size-xxl: 3rem;
  --font-size-xl: 2.5rem;
  --font-size-mx: 2.25rem;
  --font-size-base: 1rem;
  --font-size-s: .85rem;
  --font-size-xs: .7rem;
  --font-code: monospace;
  --shadow-none: none;
  --shadow-xs: 0 1px 2px rgba(0, 0, 0, .1);
  --shadow-s: 0 2px 4px rgba(0, 0, 0, .1);
  --shadow-m: 0 4px 6px rgba(0, 0, 0, .1);
  --shadow-l: 0 6px 8px rgba(0, 0, 0, .1);
  --shadow-xl: 0 8px 10px rgba(0, 0, 0, .1);
  --header-color: #ffffff;
  --header-size: 56px;
  --header-size-content: 48px;
  --side-menu-size: 300px;
  --bottom-bar-size: 56px;
  --gutter-width: 16px;
  --container-box-margin: 113px;
  --os-safe-area-top: env(safe-area-inset-top);
  --os-safe-area-right: env(safe-area-inset-right);
  --os-safe-area-bottom: env(safe-area-inset-bottom);
  --os-safe-area-left: env(safe-area-inset-left);
  --brand-width: 5.35rem;
  --brand-height: 2.3rem;
}

:root {
  --font-family: "Rubik", sans-serif;
  --header-bg: #fff;
  --footer-bg: #fff;
  --color-navbar: var(--color-black-1);
  --color-navbar--hover: var(--color-neutral-5);
}

:root {
  --color-primary: #92298D;
  --color-primary-darken: #BF3E96;
  --color-primary-hover: #be35b7;
  --color-primary-selected: rgba(20, 110, 245, .12);
  --color-primary-lightest: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9));
  --color-secondary: #fff;
  --color-secondary-darken: #6FCBDC;
  --color-orange-0: #F16722;
  --color-orange-1: #ED2529;
  --color-light-blue-0: #3FD4F0;
  --color-green-0: #32A071;
  --color-white-0: #FFFFFF;
  --color-white-1: #FAFAFA;
  --color-white-2: #F3F3F3;
  --color-white-3: #F7FAFA;
  --color-black-0: #000;
  --color-black-1: #50555C;
  --color-black-2: #616161;
  --color-black-3: #F9F9F9;
  --color-pink-1: #D3157A;
  --color-side-nav-text: #425166;
  --color-dashboard-1: #14A99B;
  --color-dashboard-2: #ED6612;
  --color-dashboard-3: #7D30CB;
  --color-dashboard-4: #A02A58;
  --color-dashboard-5: #004977;
  --color-dashboard-6: #425166;
  --color-dashboard-7: #25C97E;
  --color-dashboard-8: #0D7F97;
}

.text-primary {
  color: #92298D !important;
}

.text-primary-darken {
  color: #BF3E96 !important;
}

.text-primary-hover {
  color: #be35b7 !important;
}

.text-primary-selected {
  color: rgba(20, 110, 245, .12) !important;
}

.text-primary-lightest {
  color: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)) !important;
}

.text-secondary {
  color: #fff !important;
}

.text-secondary-darken {
  color: #6FCBDC !important;
}

.text-orange-0 {
  color: #F16722 !important;
}

.text-orange-1 {
  color: #ED2529 !important;
}

.text-light-blue-0 {
  color: #3FD4F0 !important;
}

.text-green-0 {
  color: #32A071 !important;
}

.text-white-0 {
  color: #FFFFFF !important;
}

.text-white-1 {
  color: #FAFAFA !important;
}

.text-white-2 {
  color: #F3F3F3 !important;
}

.text-white-3 {
  color: #F7FAFA !important;
}

.text-black-0 {
  color: #000 !important;
}

.text-black-1 {
  color: #50555C !important;
}

.text-black-2 {
  color: #616161 !important;
}

.text-black-3 {
  color: #F9F9F9 !important;
}

.text-pink-1 {
  color: #D3157A !important;
}

.text-side-nav-text {
  color: #425166 !important;
}

.text-dashboard-1 {
  color: #14A99B !important;
}

.text-dashboard-2 {
  color: #ED6612 !important;
}

.text-dashboard-3 {
  color: #7D30CB !important;
}

.text-dashboard-4 {
  color: #A02A58 !important;
}

.text-dashboard-5 {
  color: #004977 !important;
}

.text-dashboard-6 {
  color: #425166 !important;
}

.text-dashboard-7 {
  color: #25C97E !important;
}

.text-dashboard-8 {
  color: #0D7F97 !important;
}

.background-primary {
  background-color: #92298D !important;
}

.background-primary-darken {
  background-color: #BF3E96 !important;
}

.background-primary-hover {
  background-color: #be35b7 !important;
}

.background-primary-selected {
  background-color: rgba(20, 110, 245, .12) !important;
}

.background-primary-lightest {
  background-color: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)) !important;
}

.background-secondary {
  background-color: #fff !important;
}

.background-secondary-darken {
  background-color: #6FCBDC !important;
}

.background-orange-0 {
  background-color: #F16722 !important;
}

.background-orange-1 {
  background-color: #ED2529 !important;
}

.background-light-blue-0 {
  background-color: #3FD4F0 !important;
}

.background-green-0 {
  background-color: #32A071 !important;
}

.background-white-0 {
  background-color: #FFFFFF !important;
}

.background-white-1 {
  background-color: #FAFAFA !important;
}

.background-white-2 {
  background-color: #F3F3F3 !important;
}

.background-white-3 {
  background-color: #F7FAFA !important;
}

.background-black-0 {
  background-color: #000 !important;
}

.background-black-1 {
  background-color: #50555C !important;
}

.background-black-2 {
  background-color: #616161 !important;
}

.background-black-3 {
  background-color: #F9F9F9 !important;
}

.background-pink-1 {
  background-color: #D3157A !important;
}

.background-side-nav-text {
  background-color: #425166 !important;
}

.background-dashboard-1 {
  background-color: #14A99B !important;
}

.background-dashboard-2 {
  background-color: #ED6612 !important;
}

.background-dashboard-3 {
  background-color: #7D30CB !important;
}

.background-dashboard-4 {
  background-color: #A02A58 !important;
}

.background-dashboard-5 {
  background-color: #004977 !important;
}

.background-dashboard-6 {
  background-color: #425166 !important;
}

.background-dashboard-7 {
  background-color: #25C97E !important;
}

.background-dashboard-8 {
  background-color: #0D7F97 !important;
}

.border-color-primary {
  border-color: #92298D !important;
}

.border-color-primary-darken {
  border-color: #BF3E96 !important;
}

.border-color-primary-hover {
  border-color: #be35b7 !important;
}

.border-color-primary-selected {
  border-color: rgba(20, 110, 245, .12) !important;
}

.border-color-primary-lightest {
  border-color: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)) !important;
}

.border-color-secondary {
  border-color: #fff !important;
}

.border-color-secondary-darken {
  border-color: #6FCBDC !important;
}

.border-color-orange-0 {
  border-color: #F16722 !important;
}

.border-color-orange-1 {
  border-color: #ED2529 !important;
}

.border-color-light-blue-0 {
  border-color: #3FD4F0 !important;
}

.border-color-green-0 {
  border-color: #32A071 !important;
}

.border-color-white-0 {
  border-color: #FFFFFF !important;
}

.border-color-white-1 {
  border-color: #FAFAFA !important;
}

.border-color-white-2 {
  border-color: #F3F3F3 !important;
}

.border-color-white-3 {
  border-color: #F7FAFA !important;
}

.border-color-black-0 {
  border-color: #000 !important;
}

.border-color-black-1 {
  border-color: #50555C !important;
}

.border-color-black-2 {
  border-color: #616161 !important;
}

.border-color-black-3 {
  border-color: #F9F9F9 !important;
}

.border-color-pink-1 {
  border-color: #D3157A !important;
}

.border-color-side-nav-text {
  border-color: #425166 !important;
}

.border-color-dashboard-1 {
  border-color: #14A99B !important;
}

.border-color-dashboard-2 {
  border-color: #ED6612 !important;
}

.border-color-dashboard-3 {
  border-color: #7D30CB !important;
}

.border-color-dashboard-4 {
  border-color: #A02A58 !important;
}

.border-color-dashboard-5 {
  border-color: #004977 !important;
}

.border-color-dashboard-6 {
  border-color: #425166 !important;
}

.border-color-dashboard-7 {
  border-color: #25C97E !important;
}

.border-color-dashboard-8 {
  border-color: #0D7F97 !important;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format("woff2");
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  background: transparent;
  border: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8uDFHU6f4L1kA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8uDFHUzf4L1kA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8uDFHU1f4L1kA.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8uDFHU5f4L1kA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8uDFHU3f4I.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nMrXyi0A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nFrXyi0A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nDrXyi0A.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nPrXyi0A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nBrXw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nMrXyi0A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nFrXyi0A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nDrXyi0A.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nPrXyi0A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nBrXw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nMrXyi0A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nFrXyi0A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nDrXyi0A.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nPrXyi0A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/rubik/v12/iJWKBXyIfDnIV7nBrXw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: var(--font-family);
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: var(--font-size-xs);
}

@media (min-width: 768px) {
  html {
    font-size: var(--font-size-s);
  }
}

@media (min-width: 992px) {
  html {
    font-size: var(--font-size-base);
  }
}

@media (min-width: 1600px) {
  html {
    font-size: var(--font-size-base);
  }
}

* {
  outline-color: var(--color-primary);
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: var(--font-regular);
  color: var(--color-black);
  text-align: left;
  background-color: var(--color-neutral-0);
  line-height: 1.4;
  min-height: 100vh;
  overflow-x: hidden;
}

[dir="rtl"] body {
  text-align: right;
}

.body--flex {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: 0;
  border-top: 1px solid var(--color-neutral-4);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--color-primary);
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  transition: color .3s ease-in-out;
}

a:hover {
  color: var(--color-primary);
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* Accessibility Styles */
.has-accessible-features a:focus {
  background-color: var(--color-focus-outer);
  box-shadow: 0 2px var(--color-focus-inner);
  color: var(--color-neutral-10);
}

.has-accessible-features a:focus,
.has-accessible-features a:focus:hover {
  text-decoration: none;
}

.has-accessible-features a[disabled] {
  color: var(--color-neutral-6);
}

/* Accessibility Styles */
.has-accessible-features [data-checkbox]:before {
  border-color: var(--color-neutral-7);
}

.has-accessible-features [data-checkbox]:hover:before {
  border-color: var(--color-neutral-8);
}

.has-accessible-features [data-checkbox]:checked:before {
  border-color: var(--color-primary);
}

.has-accessible-features [data-checkbox]:focus:before {
  border-color: var(--color-focus-inner);
}

.has-accessible-features [data-checkbox] {
  border-radius: var(--border-radius-soft);
}

/* Accessibility Styles */
.has-accessible-features [data-switch]:empty:before {
  border-color: var(--color-neutral-7);
  background-color: var(--color-neutral-7);
}

.has-accessible-features [data-switch]:hover:before {
  border-color: var(--color-neutral-8);
  background-color: var(--color-neutral-8);
}

.has-accessible-features [data-switch]:checked:before {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}

.has-accessible-features [data-switch]:focus:before {
  border-color: var(--color-focus-inner);
}

.has-accessible-features [data-switch] {
  border-radius: var(--border-radius-rounded);
}

.font-size-display {
  font-size: var(--font-size-display);
  font-weight: var(--font-regular);
  line-height: 1.25;
}

.heading1 {
  font-size: var(--font-size-h1);
  font-weight: var(--font-regular);
  line-height: 1.25;
}

.heading2 {
  font-size: var(--font-size-h2);
  font-weight: var(--font-regular);
  line-height: 1.25;
}

.heading3 {
  font-size: var(--font-size-h3);
  font-weight: var(--font-regular);
  line-height: 1.25;
}

.heading4 {
  font-size: var(--font-size-h4);
  font-weight: var(--font-regular);
  line-height: 1.25;
}

.heading5 {
  font-size: var(--font-size-h5);
  font-weight: var(--font-regular);
  line-height: 1.25;
}

.heading6 {
  font-size: var(--font-size-h6);
  font-weight: var(--font-regular);
  line-height: 1.25;
}

.font-size-base {
  font-size: var(--font-size-base) !important;
}

.font-size-s {
  font-size: var(--font-size-s) !important;
}

.font-size-xs {
  font-size: var(--font-size-xs) !important;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.font-light {
  font-weight: var(--font-light);
}

.font-regular {
  font-weight: var(--font-regular);
}

.font-semi-bold {
  font-weight: var(--font-semi-bold);
}

.font-bold {
  font-weight: var(--font-bold);
}

.bold {
  font-weight: var(--font-bold);
}

@media (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: calc(var(--font-size-h1) - 2px);
  }
  h2 {
    font-size: calc(var(--font-size-h2) - 2px);
  }
  h3 {
    font-size: calc(var(--font-size-h3) - 2px);
  }
  h4 {
    font-size: calc(var(--font-size-h4) - 1px);
  }
  h5 {
    font-size: calc(var(--font-size-h5) - 1px);
  }
  h6 {
    font-size: calc(var(--font-size-h6) - 1px);
  }
  .font-size-display {
    font-size: calc(var(--font-size-display) - 2px);
  }
  .heading1 {
    font-size: calc(var(--font-size-h1) - 2px);
  }
  .heading2 {
    font-size: calc(var(--font-size-h2) - 2px);
  }
  .heading3 {
    font-size: calc(var(--font-size-h3) - 2px);
  }
  .heading4 {
    font-size: calc(var(--font-size-h4) - 1px);
  }
  .heading5 {
    font-size: calc(var(--font-size-h5) - 1px);
  }
  .heading6 {
    font-size: calc(var(--font-size-h6) - 1px);
  }
}

@media (min-width: 0) and (max-width: 767px) {
  h1 {
    font-size: calc(var(--font-size-h1) - 4px);
  }
  h2 {
    font-size: calc(var(--font-size-h2) - 4px);
  }
  h3 {
    font-size: calc(var(--font-size-h3) - 4px);
  }
  h4 {
    font-size: calc(var(--font-size-h4) - 2px);
  }
  h5 {
    font-size: calc(var(--font-size-h5) - 2px);
  }
  h6 {
    font-size: calc(var(--font-size-h6) - 2px);
  }
  .font-size-display {
    font-size: calc(var(--font-size-display) - 4px);
  }
  .heading1 {
    font-size: calc(var(--font-size-h1) - 4px);
  }
  .heading2 {
    font-size: calc(var(--font-size-h2) - 4px);
  }
  .heading3 {
    font-size: calc(var(--font-size-h3) - 4px);
  }
  .heading4 {
    font-size: calc(var(--font-size-h4) - 2px);
  }
  .heading5 {
    font-size: calc(var(--font-size-h5) - 2px);
  }
  .heading6 {
    font-size: calc(var(--font-size-h6) - 2px);
  }
}

:root {
  --color-red-lightest: #faeaea;
  --color-red-lighter: #e9aaaa;
  --color-red-light: #d96a6a;
  --color-red: #c92a2a;
  --color-red-dark: #ab2424;
  --color-red-darker: #8d1d1d;
  --color-red-darkest: #6f1717;
  --color-pink-lightest: #fbebf0;
  --color-pink-lighter: #efadc4;
  --color-pink-lighter-1: #F6DBEB;
  --color-pink-light: #e27098;
  --color-pink: #d6336c;
  --color-pink-1: #D3157A;
  --color-pink-dark: #b62b5c;
  --color-pink-darker: #96244c;
  --color-pink-darkest: #761c3b;
  --color-grape-lightest: #f7ecfa;
  --color-grape-lighter: #dfb2e9;
  --color-grape-light: #c678d9;
  --color-grape-light-1: #EFDEED;
  --color-grape: #ae3ec9;
  --color-grape-dark: #9435ab;
  --color-grape-darker: #7a2b8d;
  --color-grape-darkest: #60226f;
  --color-grape-darkest-1: #92298D;
  --color-violet-lightest: #f1edfd;
  --color-violet-lighter: #c6b6f6;
  --color-violet-light: #9b7fef;
  --color-violet: #7048e8;
  --color-violet-dark: #5f3dc5;
  --color-violet-darker: #4e32a2;
  --color-violet-darkest: #3e2880;
  --color-violet-darkest-1: #1C0F5F;
  --color-indigo-lightest: #eceffd;
  --color-indigo-lighter: #b3c1f7;
  --color-indigo-light: #7b92f1;
  --color-indigo: #4263eb;
  --color-indigo-dark: #3854c8;
  --color-indigo-darker: #2e45a5;
  --color-indigo-darkest: #1C0F5F;
  --color-blue-lightest: #e8f2fa;
  --color-blue-lighter: #a3c9ea;
  --color-blue-light: #5fa1db;
  --color-blue-light-1: #E2DBE8;
  --color-blue: #1a79cb;
  --color-blue-dark: #1667ad;
  --color-blue-darker: #12558e;
  --color-blue-darkest: #0e4370;
  --color-blue-darkest-1: #0e4370;
  --color-cyan-lightest: #e7f2f4;
  --color-cyan-lighter: #9eccd3;
  --color-cyan-light: #56a6b2;
  --color-cyan: #0d8091;
  --color-cyan-dark: #0b6d7b;
  --color-cyan-darker: #095a66;
  --color-cyan-darkest: #074650;
  --color-teal-lightest: #e6f2ef;
  --color-teal-lighter: #9cccbd;
  --color-teal-light: #52a58c;
  --color-teal: #087f5b;
  --color-teal-dark: #076c4d;
  --color-teal-darker: #065940;
  --color-teal-darkest: #044632;
  --color-green-lightest: #ebf7ed;
  --color-green-lighter: #afe0b8;
  --color-green-light: #73c982;
  --color-green: #37b24d;
  --color-green-dark: #2f9741;
  --color-green-darker: #267d36;
  --color-green-darkest: #1e622a;
  --color-lime-lightest: #f1f8e8;
  --color-lime-lighter: #c7e3a2;
  --color-lime-light: #9ecd5c;
  --color-lime: #74b816;
  --color-lime-dark: #639c13;
  --color-lime-darker: #51810f;
  --color-lime-darkest: #40650c;
  --color-orange-lightest: #fef0e6;
  --color-orange-lighter: #fcc29c;
  --color-orange-light: #f99551;
  --color-orange: #f76707;
  --color-orange-dark: #d25806;
  --color-orange-darker: #ad4805;
  --color-orange-darkest: #883904;
  --color-orange-darkest-1: #ED2529;
  --color-yellow-lightest: #fef5e5;
  --color-yellow-lightest-1: #FAEFE9;
  --color-yellow-lighter: #fbd999;
  --color-yellow-light: #f8bc4c;
  --color-yellow-light-1: #FAB963;
  --color-yellow: #f59f00;
  --color-yellow-dark: #d08700;
  --color-yellow-darker: #ac6f00;
  --color-yellow-darkest: #875700;
  --color-gray-light: #F8F8FA;
  --color-gray-darkest: #616161;
  --color-gray-darkest-1: #505050;
  --color-neutral-0: #ffffff;
  --color-neutral-1: #f8f9fa;
  --color-neutral-2: #f1f3f5;
  --color-neutral-3: #e9ecef;
  --color-neutral-4: #dee2e6;
  --color-neutral-5: #ced4da;
  --color-neutral-6: #adb5bd;
  --color-neutral-7: #6a7178;
  --color-neutral-8: #4f575e;
  --color-neutral-9: #272b30;
  --color-neutral-10: #101213;
  --color-neutral-11: #18191F;
  --color-shade-0: #000000;
  --color-shade-1: #0a0a0a;
  --color-shade-2: #141414;
  --color-shade-3: #1e1e1e;
  --color-shade-4: #282828;
  --color-shade-5: #323232;
  --color-shade-6: #3b3b3b;
  --color-shade-7: #454545;
  --color-shade-8: #4f4f4f;
  --color-shade-9: #595959;
  --color-shade-10: #636363;
  --color-shade-11: #6c6c6c;
  --color-shade-12: #767676;
  --color-shade-13: #808080;
  --color-shade-14: #8a8a8a;
  --color-shade-15: #949494;
  --color-shade-16: #9d9d9d;
  --color-shade-17: #a7a7a7;
  --color-shade-18: #b1b1b1;
  --color-shade-19: #bbbbbb;
  --color-shade-20: #c5c5c5;
  --color-shade-21: #cecece;
  --color-shade-22: #d8d8d8;
  --color-shade-23: #e2e2e2;
  --color-shade-24: #ececec;
  --color-shade-25: #f6f6f6;
  --color-error-light: #fceaea;
  --color-error: #dc2020;
  --color-warning-light: #fdf6e5;
  --color-warning: #e9a100;
  --color-success-light: #eaf3eb;
  --color-success: #29823b;
  --color-info-light: #e5f5fc;
  --color-info: #017aad;
}

.text-red-lightest {
  color: #faeaea !important;
}

.text-red-lighter {
  color: #e9aaaa !important;
}

.text-red-light {
  color: #d96a6a !important;
}

.text-red {
  color: #c92a2a !important;
}

.text-red-dark {
  color: #ab2424 !important;
}

.text-red-darker {
  color: #8d1d1d !important;
}

.text-red-darkest {
  color: #6f1717 !important;
}

.text-pink-lightest {
  color: #fbebf0 !important;
}

.text-pink-lighter {
  color: #efadc4 !important;
}

.text-pink-lighter-1 {
  color: #F6DBEB !important;
}

.text-pink-light {
  color: #e27098 !important;
}

.text-pink {
  color: #d6336c !important;
}

.text-pink-1 {
  color: #D3157A !important;
}

.text-pink-dark {
  color: #b62b5c !important;
}

.text-pink-darker {
  color: #96244c !important;
}

.text-pink-darkest {
  color: #761c3b !important;
}

.text-grape-lightest {
  color: #f7ecfa !important;
}

.text-grape-lighter {
  color: #dfb2e9 !important;
}

.text-grape-light {
  color: #c678d9 !important;
}

.text-grape-light-1 {
  color: #EFDEED !important;
}

.text-grape {
  color: #ae3ec9 !important;
}

.text-grape-dark {
  color: #9435ab !important;
}

.text-grape-darker {
  color: #7a2b8d !important;
}

.text-grape-darkest {
  color: #60226f !important;
}

.text-grape-darkest-1 {
  color: #92298D !important;
}

.text-violet-lightest {
  color: #f1edfd !important;
}

.text-violet-lighter {
  color: #c6b6f6 !important;
}

.text-violet-light {
  color: #9b7fef !important;
}

.text-violet {
  color: #7048e8 !important;
}

.text-violet-dark {
  color: #5f3dc5 !important;
}

.text-violet-darker {
  color: #4e32a2 !important;
}

.text-violet-darkest {
  color: #3e2880 !important;
}

.text-violet-darkest-1 {
  color: #1C0F5F !important;
}

.text-indigo-lightest {
  color: #eceffd !important;
}

.text-indigo-lighter {
  color: #b3c1f7 !important;
}

.text-indigo-light {
  color: #7b92f1 !important;
}

.text-indigo {
  color: #4263eb !important;
}

.text-indigo-dark {
  color: #3854c8 !important;
}

.text-indigo-darker {
  color: #2e45a5 !important;
}

.text-indigo-darkest {
  color: #1C0F5F !important;
}

.text-blue-lightest {
  color: #e8f2fa !important;
}

.text-blue-lighter {
  color: #a3c9ea !important;
}

.text-blue-light {
  color: #5fa1db !important;
}

.text-blue-light-1 {
  color: #E2DBE8 !important;
}

.text-blue {
  color: #1a79cb !important;
}

.text-blue-dark {
  color: #1667ad !important;
}

.text-blue-darker {
  color: #12558e !important;
}

.text-blue-darkest {
  color: #0e4370 !important;
}

.text-blue-darkest-1 {
  color: #0e4370 !important;
}

.text-cyan-lightest {
  color: #e7f2f4 !important;
}

.text-cyan-lighter {
  color: #9eccd3 !important;
}

.text-cyan-light {
  color: #56a6b2 !important;
}

.text-cyan {
  color: #0d8091 !important;
}

.text-cyan-dark {
  color: #0b6d7b !important;
}

.text-cyan-darker {
  color: #095a66 !important;
}

.text-cyan-darkest {
  color: #074650 !important;
}

.text-teal-lightest {
  color: #e6f2ef !important;
}

.text-teal-lighter {
  color: #9cccbd !important;
}

.text-teal-light {
  color: #52a58c !important;
}

.text-teal {
  color: #087f5b !important;
}

.text-teal-dark {
  color: #076c4d !important;
}

.text-teal-darker {
  color: #065940 !important;
}

.text-teal-darkest {
  color: #044632 !important;
}

.text-green-lightest {
  color: #ebf7ed !important;
}

.text-green-lighter {
  color: #afe0b8 !important;
}

.text-green-light {
  color: #73c982 !important;
}

.text-green {
  color: #37b24d !important;
}

.text-green-dark {
  color: #2f9741 !important;
}

.text-green-darker {
  color: #267d36 !important;
}

.text-green-darkest {
  color: #1e622a !important;
}

.text-lime-lightest {
  color: #f1f8e8 !important;
}

.text-lime-lighter {
  color: #c7e3a2 !important;
}

.text-lime-light {
  color: #9ecd5c !important;
}

.text-lime {
  color: #74b816 !important;
}

.text-lime-dark {
  color: #639c13 !important;
}

.text-lime-darker {
  color: #51810f !important;
}

.text-lime-darkest {
  color: #40650c !important;
}

.text-orange-lightest {
  color: #fef0e6 !important;
}

.text-orange-lighter {
  color: #fcc29c !important;
}

.text-orange-light {
  color: #f99551 !important;
}

.text-orange {
  color: #f76707 !important;
}

.text-orange-dark {
  color: #d25806 !important;
}

.text-orange-darker {
  color: #ad4805 !important;
}

.text-orange-darkest {
  color: #883904 !important;
}

.text-orange-darkest-1 {
  color: #ED2529 !important;
}

.text-yellow-lightest {
  color: #fef5e5 !important;
}

.text-yellow-lightest-1 {
  color: #FAEFE9 !important;
}

.text-yellow-lighter {
  color: #fbd999 !important;
}

.text-yellow-light {
  color: #f8bc4c !important;
}

.text-yellow-light-1 {
  color: #FAB963 !important;
}

.text-yellow {
  color: #f59f00 !important;
}

.text-yellow-dark {
  color: #d08700 !important;
}

.text-yellow-darker {
  color: #ac6f00 !important;
}

.text-yellow-darkest {
  color: #875700 !important;
}

.text-gray-light {
  color: #F8F8FA !important;
}

.text-gray-darkest {
  color: #616161 !important;
}

.text-gray-darkest-1 {
  color: #505050 !important;
}

.text-neutral-0 {
  color: #ffffff !important;
}

.text-neutral-1 {
  color: #f8f9fa !important;
}

.text-neutral-2 {
  color: #f1f3f5 !important;
}

.text-neutral-3 {
  color: #e9ecef !important;
}

.text-neutral-4 {
  color: #dee2e6 !important;
}

.text-neutral-5 {
  color: #ced4da !important;
}

.text-neutral-6 {
  color: #adb5bd !important;
}

.text-neutral-7 {
  color: #6a7178 !important;
}

.text-neutral-8 {
  color: #4f575e !important;
}

.text-neutral-9 {
  color: #272b30 !important;
}

.text-neutral-10 {
  color: #101213 !important;
}

.text-neutral-11 {
  color: #18191F !important;
}

.text-shade-0 {
  color: #000000 !important;
}

.text-shade-1 {
  color: #0a0a0a !important;
}

.text-shade-2 {
  color: #141414 !important;
}

.text-shade-3 {
  color: #1e1e1e !important;
}

.text-shade-4 {
  color: #282828 !important;
}

.text-shade-5 {
  color: #323232 !important;
}

.text-shade-6 {
  color: #3b3b3b !important;
}

.text-shade-7 {
  color: #454545 !important;
}

.text-shade-8 {
  color: #4f4f4f !important;
}

.text-shade-9 {
  color: #595959 !important;
}

.text-shade-10 {
  color: #636363 !important;
}

.text-shade-11 {
  color: #6c6c6c !important;
}

.text-shade-12 {
  color: #767676 !important;
}

.text-shade-13 {
  color: #808080 !important;
}

.text-shade-14 {
  color: #8a8a8a !important;
}

.text-shade-15 {
  color: #949494 !important;
}

.text-shade-16 {
  color: #9d9d9d !important;
}

.text-shade-17 {
  color: #a7a7a7 !important;
}

.text-shade-18 {
  color: #b1b1b1 !important;
}

.text-shade-19 {
  color: #bbbbbb !important;
}

.text-shade-20 {
  color: #c5c5c5 !important;
}

.text-shade-21 {
  color: #cecece !important;
}

.text-shade-22 {
  color: #d8d8d8 !important;
}

.text-shade-23 {
  color: #e2e2e2 !important;
}

.text-shade-24 {
  color: #ececec !important;
}

.text-shade-25 {
  color: #f6f6f6 !important;
}

.text-error-light {
  color: #fceaea !important;
}

.text-error {
  color: #dc2020 !important;
}

.text-warning-light {
  color: #fdf6e5 !important;
}

.text-warning {
  color: #e9a100 !important;
}

.text-success-light {
  color: #eaf3eb !important;
}

.text-success {
  color: #29823b !important;
}

.text-info-light {
  color: #e5f5fc !important;
}

.text-info {
  color: #017aad !important;
}

.background-red-lightest {
  background-color: #faeaea !important;
}

.background-red-lighter {
  background-color: #e9aaaa !important;
}

.background-red-light {
  background-color: #d96a6a !important;
}

.background-red {
  background-color: #c92a2a !important;
}

.background-red-dark {
  background-color: #ab2424 !important;
}

.background-red-darker {
  background-color: #8d1d1d !important;
}

.background-red-darkest {
  background-color: #6f1717 !important;
}

.background-pink-lightest {
  background-color: #fbebf0 !important;
}

.background-pink-lighter {
  background-color: #efadc4 !important;
}

.background-pink-lighter-1 {
  background-color: #F6DBEB !important;
}

.background-pink-light {
  background-color: #e27098 !important;
}

.background-pink {
  background-color: #d6336c !important;
}

.background-pink-1 {
  background-color: #D3157A !important;
}

.background-pink-dark {
  background-color: #b62b5c !important;
}

.background-pink-darker {
  background-color: #96244c !important;
}

.background-pink-darkest {
  background-color: #761c3b !important;
}

.background-grape-lightest {
  background-color: #f7ecfa !important;
}

.background-grape-lighter {
  background-color: #dfb2e9 !important;
}

.background-grape-light {
  background-color: #c678d9 !important;
}

.background-grape-light-1 {
  background-color: #EFDEED !important;
}

.background-grape {
  background-color: #ae3ec9 !important;
}

.background-grape-dark {
  background-color: #9435ab !important;
}

.background-grape-darker {
  background-color: #7a2b8d !important;
}

.background-grape-darkest {
  background-color: #60226f !important;
}

.background-grape-darkest-1 {
  background-color: #92298D !important;
}

.background-violet-lightest {
  background-color: #f1edfd !important;
}

.background-violet-lighter {
  background-color: #c6b6f6 !important;
}

.background-violet-light {
  background-color: #9b7fef !important;
}

.background-violet {
  background-color: #7048e8 !important;
}

.background-violet-dark {
  background-color: #5f3dc5 !important;
}

.background-violet-darker {
  background-color: #4e32a2 !important;
}

.background-violet-darkest {
  background-color: #3e2880 !important;
}

.background-violet-darkest-1 {
  background-color: #1C0F5F !important;
}

.background-indigo-lightest {
  background-color: #eceffd !important;
}

.background-indigo-lighter {
  background-color: #b3c1f7 !important;
}

.background-indigo-light {
  background-color: #7b92f1 !important;
}

.background-indigo {
  background-color: #4263eb !important;
}

.background-indigo-dark {
  background-color: #3854c8 !important;
}

.background-indigo-darker {
  background-color: #2e45a5 !important;
}

.background-indigo-darkest {
  background-color: #1C0F5F !important;
}

.background-blue-lightest {
  background-color: #e8f2fa !important;
}

.background-blue-lighter {
  background-color: #a3c9ea !important;
}

.background-blue-light {
  background-color: #5fa1db !important;
}

.background-blue-light-1 {
  background-color: #E2DBE8 !important;
}

.background-blue {
  background-color: #1a79cb !important;
}

.background-blue-dark {
  background-color: #1667ad !important;
}

.background-blue-darker {
  background-color: #12558e !important;
}

.background-blue-darkest {
  background-color: #0e4370 !important;
}

.background-blue-darkest-1 {
  background-color: #0e4370 !important;
}

.background-cyan-lightest {
  background-color: #e7f2f4 !important;
}

.background-cyan-lighter {
  background-color: #9eccd3 !important;
}

.background-cyan-light {
  background-color: #56a6b2 !important;
}

.background-cyan {
  background-color: #0d8091 !important;
}

.background-cyan-dark {
  background-color: #0b6d7b !important;
}

.background-cyan-darker {
  background-color: #095a66 !important;
}

.background-cyan-darkest {
  background-color: #074650 !important;
}

.background-teal-lightest {
  background-color: #e6f2ef !important;
}

.background-teal-lighter {
  background-color: #9cccbd !important;
}

.background-teal-light {
  background-color: #52a58c !important;
}

.background-teal {
  background-color: #087f5b !important;
}

.background-teal-dark {
  background-color: #076c4d !important;
}

.background-teal-darker {
  background-color: #065940 !important;
}

.background-teal-darkest {
  background-color: #044632 !important;
}

.background-green-lightest {
  background-color: #ebf7ed !important;
}

.background-green-lighter {
  background-color: #afe0b8 !important;
}

.background-green-light {
  background-color: #73c982 !important;
}

.background-green {
  background-color: #37b24d !important;
}

.background-green-dark {
  background-color: #2f9741 !important;
}

.background-green-darker {
  background-color: #267d36 !important;
}

.background-green-darkest {
  background-color: #1e622a !important;
}

.background-lime-lightest {
  background-color: #f1f8e8 !important;
}

.background-lime-lighter {
  background-color: #c7e3a2 !important;
}

.background-lime-light {
  background-color: #9ecd5c !important;
}

.background-lime {
  background-color: #74b816 !important;
}

.background-lime-dark {
  background-color: #639c13 !important;
}

.background-lime-darker {
  background-color: #51810f !important;
}

.background-lime-darkest {
  background-color: #40650c !important;
}

.background-orange-lightest {
  background-color: #fef0e6 !important;
}

.background-orange-lighter {
  background-color: #fcc29c !important;
}

.background-orange-light {
  background-color: #f99551 !important;
}

.background-orange {
  background-color: #f76707 !important;
}

.background-orange-dark {
  background-color: #d25806 !important;
}

.background-orange-darker {
  background-color: #ad4805 !important;
}

.background-orange-darkest {
  background-color: #883904 !important;
}

.background-orange-darkest-1 {
  background-color: #ED2529 !important;
}

.background-yellow-lightest {
  background-color: #fef5e5 !important;
}

.background-yellow-lightest-1 {
  background-color: #FAEFE9 !important;
}

.background-yellow-lighter {
  background-color: #fbd999 !important;
}

.background-yellow-light {
  background-color: #f8bc4c !important;
}

.background-yellow-light-1 {
  background-color: #FAB963 !important;
}

.background-yellow {
  background-color: #f59f00 !important;
}

.background-yellow-dark {
  background-color: #d08700 !important;
}

.background-yellow-darker {
  background-color: #ac6f00 !important;
}

.background-yellow-darkest {
  background-color: #875700 !important;
}

.background-gray-light {
  background-color: #F8F8FA !important;
}

.background-gray-darkest {
  background-color: #616161 !important;
}

.background-gray-darkest-1 {
  background-color: #505050 !important;
}

.background-neutral-0 {
  background-color: #ffffff !important;
}

.background-neutral-1 {
  background-color: #f8f9fa !important;
}

.background-neutral-2 {
  background-color: #f1f3f5 !important;
}

.background-neutral-3 {
  background-color: #e9ecef !important;
}

.background-neutral-4 {
  background-color: #dee2e6 !important;
}

.background-neutral-5 {
  background-color: #ced4da !important;
}

.background-neutral-6 {
  background-color: #adb5bd !important;
}

.background-neutral-7 {
  background-color: #6a7178 !important;
}

.background-neutral-8 {
  background-color: #4f575e !important;
}

.background-neutral-9 {
  background-color: #272b30 !important;
}

.background-neutral-10 {
  background-color: #101213 !important;
}

.background-neutral-11 {
  background-color: #18191F !important;
}

.background-shade-0 {
  background-color: #000000 !important;
}

.background-shade-1 {
  background-color: #0a0a0a !important;
}

.background-shade-2 {
  background-color: #141414 !important;
}

.background-shade-3 {
  background-color: #1e1e1e !important;
}

.background-shade-4 {
  background-color: #282828 !important;
}

.background-shade-5 {
  background-color: #323232 !important;
}

.background-shade-6 {
  background-color: #3b3b3b !important;
}

.background-shade-7 {
  background-color: #454545 !important;
}

.background-shade-8 {
  background-color: #4f4f4f !important;
}

.background-shade-9 {
  background-color: #595959 !important;
}

.background-shade-10 {
  background-color: #636363 !important;
}

.background-shade-11 {
  background-color: #6c6c6c !important;
}

.background-shade-12 {
  background-color: #767676 !important;
}

.background-shade-13 {
  background-color: #808080 !important;
}

.background-shade-14 {
  background-color: #8a8a8a !important;
}

.background-shade-15 {
  background-color: #949494 !important;
}

.background-shade-16 {
  background-color: #9d9d9d !important;
}

.background-shade-17 {
  background-color: #a7a7a7 !important;
}

.background-shade-18 {
  background-color: #b1b1b1 !important;
}

.background-shade-19 {
  background-color: #bbbbbb !important;
}

.background-shade-20 {
  background-color: #c5c5c5 !important;
}

.background-shade-21 {
  background-color: #cecece !important;
}

.background-shade-22 {
  background-color: #d8d8d8 !important;
}

.background-shade-23 {
  background-color: #e2e2e2 !important;
}

.background-shade-24 {
  background-color: #ececec !important;
}

.background-shade-25 {
  background-color: #f6f6f6 !important;
}

.background-error-light {
  background-color: #fceaea !important;
}

.background-error {
  background-color: #dc2020 !important;
}

.background-warning-light {
  background-color: #fdf6e5 !important;
}

.background-warning {
  background-color: #e9a100 !important;
}

.background-success-light {
  background-color: #eaf3eb !important;
}

.background-success {
  background-color: #29823b !important;
}

.background-info-light {
  background-color: #e5f5fc !important;
}

.background-info {
  background-color: #017aad !important;
}

.border-color-red-lightest {
  border-color: #faeaea !important;
}

.border-color-red-lighter {
  border-color: #e9aaaa !important;
}

.border-color-red-light {
  border-color: #d96a6a !important;
}

.border-color-red {
  border-color: #c92a2a !important;
}

.border-color-red-dark {
  border-color: #ab2424 !important;
}

.border-color-red-darker {
  border-color: #8d1d1d !important;
}

.border-color-red-darkest {
  border-color: #6f1717 !important;
}

.border-color-pink-lightest {
  border-color: #fbebf0 !important;
}

.border-color-pink-lighter {
  border-color: #efadc4 !important;
}

.border-color-pink-lighter-1 {
  border-color: #F6DBEB !important;
}

.border-color-pink-light {
  border-color: #e27098 !important;
}

.border-color-pink {
  border-color: #d6336c !important;
}

.border-color-pink-1 {
  border-color: #D3157A !important;
}

.border-color-pink-dark {
  border-color: #b62b5c !important;
}

.border-color-pink-darker {
  border-color: #96244c !important;
}

.border-color-pink-darkest {
  border-color: #761c3b !important;
}

.border-color-grape-lightest {
  border-color: #f7ecfa !important;
}

.border-color-grape-lighter {
  border-color: #dfb2e9 !important;
}

.border-color-grape-light {
  border-color: #c678d9 !important;
}

.border-color-grape-light-1 {
  border-color: #EFDEED !important;
}

.border-color-grape {
  border-color: #ae3ec9 !important;
}

.border-color-grape-dark {
  border-color: #9435ab !important;
}

.border-color-grape-darker {
  border-color: #7a2b8d !important;
}

.border-color-grape-darkest {
  border-color: #60226f !important;
}

.border-color-grape-darkest-1 {
  border-color: #92298D !important;
}

.border-color-violet-lightest {
  border-color: #f1edfd !important;
}

.border-color-violet-lighter {
  border-color: #c6b6f6 !important;
}

.border-color-violet-light {
  border-color: #9b7fef !important;
}

.border-color-violet {
  border-color: #7048e8 !important;
}

.border-color-violet-dark {
  border-color: #5f3dc5 !important;
}

.border-color-violet-darker {
  border-color: #4e32a2 !important;
}

.border-color-violet-darkest {
  border-color: #3e2880 !important;
}

.border-color-violet-darkest-1 {
  border-color: #1C0F5F !important;
}

.border-color-indigo-lightest {
  border-color: #eceffd !important;
}

.border-color-indigo-lighter {
  border-color: #b3c1f7 !important;
}

.border-color-indigo-light {
  border-color: #7b92f1 !important;
}

.border-color-indigo {
  border-color: #4263eb !important;
}

.border-color-indigo-dark {
  border-color: #3854c8 !important;
}

.border-color-indigo-darker {
  border-color: #2e45a5 !important;
}

.border-color-indigo-darkest {
  border-color: #1C0F5F !important;
}

.border-color-blue-lightest {
  border-color: #e8f2fa !important;
}

.border-color-blue-lighter {
  border-color: #a3c9ea !important;
}

.border-color-blue-light {
  border-color: #5fa1db !important;
}

.border-color-blue-light-1 {
  border-color: #E2DBE8 !important;
}

.border-color-blue {
  border-color: #1a79cb !important;
}

.border-color-blue-dark {
  border-color: #1667ad !important;
}

.border-color-blue-darker {
  border-color: #12558e !important;
}

.border-color-blue-darkest {
  border-color: #0e4370 !important;
}

.border-color-blue-darkest-1 {
  border-color: #0e4370 !important;
}

.border-color-cyan-lightest {
  border-color: #e7f2f4 !important;
}

.border-color-cyan-lighter {
  border-color: #9eccd3 !important;
}

.border-color-cyan-light {
  border-color: #56a6b2 !important;
}

.border-color-cyan {
  border-color: #0d8091 !important;
}

.border-color-cyan-dark {
  border-color: #0b6d7b !important;
}

.border-color-cyan-darker {
  border-color: #095a66 !important;
}

.border-color-cyan-darkest {
  border-color: #074650 !important;
}

.border-color-teal-lightest {
  border-color: #e6f2ef !important;
}

.border-color-teal-lighter {
  border-color: #9cccbd !important;
}

.border-color-teal-light {
  border-color: #52a58c !important;
}

.border-color-teal {
  border-color: #087f5b !important;
}

.border-color-teal-dark {
  border-color: #076c4d !important;
}

.border-color-teal-darker {
  border-color: #065940 !important;
}

.border-color-teal-darkest {
  border-color: #044632 !important;
}

.border-color-green-lightest {
  border-color: #ebf7ed !important;
}

.border-color-green-lighter {
  border-color: #afe0b8 !important;
}

.border-color-green-light {
  border-color: #73c982 !important;
}

.border-color-green {
  border-color: #37b24d !important;
}

.border-color-green-dark {
  border-color: #2f9741 !important;
}

.border-color-green-darker {
  border-color: #267d36 !important;
}

.border-color-green-darkest {
  border-color: #1e622a !important;
}

.border-color-lime-lightest {
  border-color: #f1f8e8 !important;
}

.border-color-lime-lighter {
  border-color: #c7e3a2 !important;
}

.border-color-lime-light {
  border-color: #9ecd5c !important;
}

.border-color-lime {
  border-color: #74b816 !important;
}

.border-color-lime-dark {
  border-color: #639c13 !important;
}

.border-color-lime-darker {
  border-color: #51810f !important;
}

.border-color-lime-darkest {
  border-color: #40650c !important;
}

.border-color-orange-lightest {
  border-color: #fef0e6 !important;
}

.border-color-orange-lighter {
  border-color: #fcc29c !important;
}

.border-color-orange-light {
  border-color: #f99551 !important;
}

.border-color-orange {
  border-color: #f76707 !important;
}

.border-color-orange-dark {
  border-color: #d25806 !important;
}

.border-color-orange-darker {
  border-color: #ad4805 !important;
}

.border-color-orange-darkest {
  border-color: #883904 !important;
}

.border-color-orange-darkest-1 {
  border-color: #ED2529 !important;
}

.border-color-yellow-lightest {
  border-color: #fef5e5 !important;
}

.border-color-yellow-lightest-1 {
  border-color: #FAEFE9 !important;
}

.border-color-yellow-lighter {
  border-color: #fbd999 !important;
}

.border-color-yellow-light {
  border-color: #f8bc4c !important;
}

.border-color-yellow-light-1 {
  border-color: #FAB963 !important;
}

.border-color-yellow {
  border-color: #f59f00 !important;
}

.border-color-yellow-dark {
  border-color: #d08700 !important;
}

.border-color-yellow-darker {
  border-color: #ac6f00 !important;
}

.border-color-yellow-darkest {
  border-color: #875700 !important;
}

.border-color-gray-light {
  border-color: #F8F8FA !important;
}

.border-color-gray-darkest {
  border-color: #616161 !important;
}

.border-color-gray-darkest-1 {
  border-color: #505050 !important;
}

.border-color-neutral-0 {
  border-color: #ffffff !important;
}

.border-color-neutral-1 {
  border-color: #f8f9fa !important;
}

.border-color-neutral-2 {
  border-color: #f1f3f5 !important;
}

.border-color-neutral-3 {
  border-color: #e9ecef !important;
}

.border-color-neutral-4 {
  border-color: #dee2e6 !important;
}

.border-color-neutral-5 {
  border-color: #ced4da !important;
}

.border-color-neutral-6 {
  border-color: #adb5bd !important;
}

.border-color-neutral-7 {
  border-color: #6a7178 !important;
}

.border-color-neutral-8 {
  border-color: #4f575e !important;
}

.border-color-neutral-9 {
  border-color: #272b30 !important;
}

.border-color-neutral-10 {
  border-color: #101213 !important;
}

.border-color-neutral-11 {
  border-color: #18191F !important;
}

.border-color-shade-0 {
  border-color: #000000 !important;
}

.border-color-shade-1 {
  border-color: #0a0a0a !important;
}

.border-color-shade-2 {
  border-color: #141414 !important;
}

.border-color-shade-3 {
  border-color: #1e1e1e !important;
}

.border-color-shade-4 {
  border-color: #282828 !important;
}

.border-color-shade-5 {
  border-color: #323232 !important;
}

.border-color-shade-6 {
  border-color: #3b3b3b !important;
}

.border-color-shade-7 {
  border-color: #454545 !important;
}

.border-color-shade-8 {
  border-color: #4f4f4f !important;
}

.border-color-shade-9 {
  border-color: #595959 !important;
}

.border-color-shade-10 {
  border-color: #636363 !important;
}

.border-color-shade-11 {
  border-color: #6c6c6c !important;
}

.border-color-shade-12 {
  border-color: #767676 !important;
}

.border-color-shade-13 {
  border-color: #808080 !important;
}

.border-color-shade-14 {
  border-color: #8a8a8a !important;
}

.border-color-shade-15 {
  border-color: #949494 !important;
}

.border-color-shade-16 {
  border-color: #9d9d9d !important;
}

.border-color-shade-17 {
  border-color: #a7a7a7 !important;
}

.border-color-shade-18 {
  border-color: #b1b1b1 !important;
}

.border-color-shade-19 {
  border-color: #bbbbbb !important;
}

.border-color-shade-20 {
  border-color: #c5c5c5 !important;
}

.border-color-shade-21 {
  border-color: #cecece !important;
}

.border-color-shade-22 {
  border-color: #d8d8d8 !important;
}

.border-color-shade-23 {
  border-color: #e2e2e2 !important;
}

.border-color-shade-24 {
  border-color: #ececec !important;
}

.border-color-shade-25 {
  border-color: #f6f6f6 !important;
}

.border-color-error-light {
  border-color: #fceaea !important;
}

.border-color-error {
  border-color: #dc2020 !important;
}

.border-color-warning-light {
  border-color: #fdf6e5 !important;
}

.border-color-warning {
  border-color: #e9a100 !important;
}

.border-color-success-light {
  border-color: #eaf3eb !important;
}

.border-color-success {
  border-color: #29823b !important;
}

.border-color-info-light {
  border-color: #e5f5fc !important;
}

.border-color-info {
  border-color: #017aad !important;
}

:root {
  --space-auto: auto;
  --space-none: 0;
  --space-xs: 0.25rem;
  --space-s: 0.5rem;
  --space-base: 1rem;
  --space-m: 1.5rem;
  --space-l: 2rem;
  --space-xl: 2.5rem;
  --space-xxl: 3rem;
}

.margin-auto {
  margin: var(--space-auto) !important;
}

.padding-auto {
  padding: var(--space-auto) !important;
}

.margin-top-auto {
  margin-top: var(--space-auto) !important;
}

.padding-top-auto {
  padding-top: var(--space-auto) !important;
}

.margin-bottom-auto {
  margin-bottom: var(--space-auto) !important;
}

.padding-bottom-auto {
  padding-bottom: var(--space-auto) !important;
}

.margin-start-auto {
  margin-inline-start: var(--space-auto) !important;
}

.padding-start-auto {
  padding-inline-start: var(--space-auto) !important;
}

.margin-end-auto {
  margin-inline-end: var(--space-auto) !important;
}

.padding-end-auto {
  padding-inline-end: var(--space-auto) !important;
}

.margin-right-auto {
  margin-right: var(--space-auto) !important;
}

.padding-right-auto {
  padding-right: var(--space-auto) !important;
}

.margin-left-auto {
  margin-left: var(--space-auto) !important;
}

.padding-left-auto {
  padding-left: var(--space-auto) !important;
}

.margin-y-auto {
  margin-top: var(--space-auto) !important;
  margin-bottom: var(--space-auto) !important;
}

.padding-y-auto {
  padding-top: var(--space-auto) !important;
  padding-bottom: var(--space-auto) !important;
}

.margin-x-auto {
  margin-left: var(--space-auto) !important;
  margin-right: var(--space-auto) !important;
}

.padding-x-auto {
  padding-left: var(--space-auto) !important;
  padding-right: var(--space-auto) !important;
}

.margin-none {
  margin: var(--space-none) !important;
}

.padding-none {
  padding: var(--space-none) !important;
}

.margin-top-none {
  margin-top: var(--space-none) !important;
}

.padding-top-none {
  padding-top: var(--space-none) !important;
}

.margin-bottom-none {
  margin-bottom: var(--space-none) !important;
}

.padding-bottom-none {
  padding-bottom: var(--space-none) !important;
}

.margin-start-none {
  margin-inline-start: var(--space-none) !important;
}

.padding-start-none {
  padding-inline-start: var(--space-none) !important;
}

.margin-end-none {
  margin-inline-end: var(--space-none) !important;
}

.padding-end-none {
  padding-inline-end: var(--space-none) !important;
}

.margin-right-none {
  margin-right: var(--space-none) !important;
}

.padding-right-none {
  padding-right: var(--space-none) !important;
}

.margin-left-none {
  margin-left: var(--space-none) !important;
}

.padding-left-none {
  padding-left: var(--space-none) !important;
}

.margin-y-none {
  margin-top: var(--space-none) !important;
  margin-bottom: var(--space-none) !important;
}

.padding-y-none {
  padding-top: var(--space-none) !important;
  padding-bottom: var(--space-none) !important;
}

.margin-x-none {
  margin-left: var(--space-none) !important;
  margin-right: var(--space-none) !important;
}

.padding-x-none {
  padding-left: var(--space-none) !important;
  padding-right: var(--space-none) !important;
}

.margin-xs {
  margin: var(--space-xs) !important;
}

.padding-xs {
  padding: var(--space-xs) !important;
}

.margin-top-xs {
  margin-top: var(--space-xs) !important;
}

.padding-top-xs {
  padding-top: var(--space-xs) !important;
}

.margin-bottom-xs {
  margin-bottom: var(--space-xs) !important;
}

.padding-bottom-xs {
  padding-bottom: var(--space-xs) !important;
}

.margin-start-xs {
  margin-inline-start: var(--space-xs) !important;
}

.padding-start-xs {
  padding-inline-start: var(--space-xs) !important;
}

.margin-end-xs {
  margin-inline-end: var(--space-xs) !important;
}

.padding-end-xs {
  padding-inline-end: var(--space-xs) !important;
}

.margin-right-xs {
  margin-right: var(--space-xs) !important;
}

.padding-right-xs {
  padding-right: var(--space-xs) !important;
}

.margin-left-xs {
  margin-left: var(--space-xs) !important;
}

.padding-left-xs {
  padding-left: var(--space-xs) !important;
}

.margin-y-xs {
  margin-top: var(--space-xs) !important;
  margin-bottom: var(--space-xs) !important;
}

.padding-y-xs {
  padding-top: var(--space-xs) !important;
  padding-bottom: var(--space-xs) !important;
}

.margin-x-xs {
  margin-left: var(--space-xs) !important;
  margin-right: var(--space-xs) !important;
}

.padding-x-xs {
  padding-left: var(--space-xs) !important;
  padding-right: var(--space-xs) !important;
}

.margin-s {
  margin: var(--space-s) !important;
}

.padding-s {
  padding: var(--space-s) !important;
}

.margin-top-s {
  margin-top: var(--space-s) !important;
}

.padding-top-s {
  padding-top: var(--space-s) !important;
}

.margin-bottom-s {
  margin-bottom: var(--space-s) !important;
}

.padding-bottom-s {
  padding-bottom: var(--space-s) !important;
}

.margin-start-s {
  margin-inline-start: var(--space-s) !important;
}

.padding-start-s {
  padding-inline-start: var(--space-s) !important;
}

.margin-end-s {
  margin-inline-end: var(--space-s) !important;
}

.padding-end-s {
  padding-inline-end: var(--space-s) !important;
}

.margin-right-s {
  margin-right: var(--space-s) !important;
}

.padding-right-s {
  padding-right: var(--space-s) !important;
}

.margin-left-s {
  margin-left: var(--space-s) !important;
}

.padding-left-s {
  padding-left: var(--space-s) !important;
}

.margin-y-s {
  margin-top: var(--space-s) !important;
  margin-bottom: var(--space-s) !important;
}

.padding-y-s {
  padding-top: var(--space-s) !important;
  padding-bottom: var(--space-s) !important;
}

.margin-x-s {
  margin-left: var(--space-s) !important;
  margin-right: var(--space-s) !important;
}

.padding-x-s {
  padding-left: var(--space-s) !important;
  padding-right: var(--space-s) !important;
}

.margin-base {
  margin: var(--space-base) !important;
}

.padding-base {
  padding: var(--space-base) !important;
}

.margin-top-base {
  margin-top: var(--space-base) !important;
}

.padding-top-base {
  padding-top: var(--space-base) !important;
}

.margin-bottom-base {
  margin-bottom: var(--space-base) !important;
}

.padding-bottom-base {
  padding-bottom: var(--space-base) !important;
}

.margin-start-base {
  margin-inline-start: var(--space-base) !important;
}

.padding-start-base {
  padding-inline-start: var(--space-base) !important;
}

.margin-end-base {
  margin-inline-end: var(--space-base) !important;
}

.padding-end-base {
  padding-inline-end: var(--space-base) !important;
}

.margin-right-base {
  margin-right: var(--space-base) !important;
}

.padding-right-base {
  padding-right: var(--space-base) !important;
}

.margin-left-base {
  margin-left: var(--space-base) !important;
}

.padding-left-base {
  padding-left: var(--space-base) !important;
}

.margin-y-base {
  margin-top: var(--space-base) !important;
  margin-bottom: var(--space-base) !important;
}

.padding-y-base {
  padding-top: var(--space-base) !important;
  padding-bottom: var(--space-base) !important;
}

.margin-x-base {
  margin-left: var(--space-base) !important;
  margin-right: var(--space-base) !important;
}

.padding-x-base {
  padding-left: var(--space-base) !important;
  padding-right: var(--space-base) !important;
}

.margin-m {
  margin: var(--space-m) !important;
}

.padding-m {
  padding: var(--space-m) !important;
}

.margin-top-m {
  margin-top: var(--space-m) !important;
}

.padding-top-m {
  padding-top: var(--space-m) !important;
}

.margin-bottom-m {
  margin-bottom: var(--space-m) !important;
}

.padding-bottom-m {
  padding-bottom: var(--space-m) !important;
}

.margin-start-m {
  margin-inline-start: var(--space-m) !important;
}

.padding-start-m {
  padding-inline-start: var(--space-m) !important;
}

.margin-end-m {
  margin-inline-end: var(--space-m) !important;
}

.padding-end-m {
  padding-inline-end: var(--space-m) !important;
}

.margin-right-m {
  margin-right: var(--space-m) !important;
}

.padding-right-m {
  padding-right: var(--space-m) !important;
}

.margin-left-m {
  margin-left: var(--space-m) !important;
}

.padding-left-m {
  padding-left: var(--space-m) !important;
}

.margin-y-m {
  margin-top: var(--space-m) !important;
  margin-bottom: var(--space-m) !important;
}

.padding-y-m {
  padding-top: var(--space-m) !important;
  padding-bottom: var(--space-m) !important;
}

.margin-x-m {
  margin-left: var(--space-m) !important;
  margin-right: var(--space-m) !important;
}

.padding-x-m {
  padding-left: var(--space-m) !important;
  padding-right: var(--space-m) !important;
}

.margin-l {
  margin: var(--space-l) !important;
}

.padding-l {
  padding: var(--space-l) !important;
}

.margin-top-l {
  margin-top: var(--space-l) !important;
}

.padding-top-l {
  padding-top: var(--space-l) !important;
}

.margin-bottom-l {
  margin-bottom: var(--space-l) !important;
}

.padding-bottom-l {
  padding-bottom: var(--space-l) !important;
}

.margin-start-l {
  margin-inline-start: var(--space-l) !important;
}

.padding-start-l {
  padding-inline-start: var(--space-l) !important;
}

.margin-end-l {
  margin-inline-end: var(--space-l) !important;
}

.padding-end-l {
  padding-inline-end: var(--space-l) !important;
}

.margin-right-l {
  margin-right: var(--space-l) !important;
}

.padding-right-l {
  padding-right: var(--space-l) !important;
}

.margin-left-l {
  margin-left: var(--space-l) !important;
}

.padding-left-l {
  padding-left: var(--space-l) !important;
}

.margin-y-l {
  margin-top: var(--space-l) !important;
  margin-bottom: var(--space-l) !important;
}

.padding-y-l {
  padding-top: var(--space-l) !important;
  padding-bottom: var(--space-l) !important;
}

.margin-x-l {
  margin-left: var(--space-l) !important;
  margin-right: var(--space-l) !important;
}

.padding-x-l {
  padding-left: var(--space-l) !important;
  padding-right: var(--space-l) !important;
}

.margin-xl {
  margin: var(--space-xl) !important;
}

.padding-xl {
  padding: var(--space-xl) !important;
}

.margin-top-xl {
  margin-top: var(--space-xl) !important;
}

.padding-top-xl {
  padding-top: var(--space-xl) !important;
}

.margin-bottom-xl {
  margin-bottom: var(--space-xl) !important;
}

.padding-bottom-xl {
  padding-bottom: var(--space-xl) !important;
}

.margin-start-xl {
  margin-inline-start: var(--space-xl) !important;
}

.padding-start-xl {
  padding-inline-start: var(--space-xl) !important;
}

.margin-end-xl {
  margin-inline-end: var(--space-xl) !important;
}

.padding-end-xl {
  padding-inline-end: var(--space-xl) !important;
}

.margin-right-xl {
  margin-right: var(--space-xl) !important;
}

.padding-right-xl {
  padding-right: var(--space-xl) !important;
}

.margin-left-xl {
  margin-left: var(--space-xl) !important;
}

.padding-left-xl {
  padding-left: var(--space-xl) !important;
}

.margin-y-xl {
  margin-top: var(--space-xl) !important;
  margin-bottom: var(--space-xl) !important;
}

.padding-y-xl {
  padding-top: var(--space-xl) !important;
  padding-bottom: var(--space-xl) !important;
}

.margin-x-xl {
  margin-left: var(--space-xl) !important;
  margin-right: var(--space-xl) !important;
}

.padding-x-xl {
  padding-left: var(--space-xl) !important;
  padding-right: var(--space-xl) !important;
}

.margin-xxl {
  margin: var(--space-xxl) !important;
}

.padding-xxl {
  padding: var(--space-xxl) !important;
}

.margin-top-xxl {
  margin-top: var(--space-xxl) !important;
}

.padding-top-xxl {
  padding-top: var(--space-xxl) !important;
}

.margin-bottom-xxl {
  margin-bottom: var(--space-xxl) !important;
}

.padding-bottom-xxl {
  padding-bottom: var(--space-xxl) !important;
}

.margin-start-xxl {
  margin-inline-start: var(--space-xxl) !important;
}

.padding-start-xxl {
  padding-inline-start: var(--space-xxl) !important;
}

.margin-end-xxl {
  margin-inline-end: var(--space-xxl) !important;
}

.padding-end-xxl {
  padding-inline-end: var(--space-xxl) !important;
}

.margin-right-xxl {
  margin-right: var(--space-xxl) !important;
}

.padding-right-xxl {
  padding-right: var(--space-xxl) !important;
}

.margin-left-xxl {
  margin-left: var(--space-xxl) !important;
}

.padding-left-xxl {
  padding-left: var(--space-xxl) !important;
}

.margin-y-xxl {
  margin-top: var(--space-xxl) !important;
  margin-bottom: var(--space-xxl) !important;
}

.padding-y-xxl {
  padding-top: var(--space-xxl) !important;
  padding-bottom: var(--space-xxl) !important;
}

.margin-x-xxl {
  margin-left: var(--space-xxl) !important;
  margin-right: var(--space-xxl) !important;
}

.padding-x-xxl {
  padding-left: var(--space-xxl) !important;
  padding-right: var(--space-xxl) !important;
}

:root {
  --border-size-none: 0;
  --border-size-base: 1px;
  --border-size-m: 2px;
  --border-size-l: 3px;
  --border-size-xl: 6px;
  --border-radius-none: 0;
  --border-radius-soft: 5px;
  --border-radius-rounded: 1em;
  --border-radius-circle: 50%;
}

.border-size-none {
  border: var(--border-size-none) solid currentColor !important;
}

.border-size-base {
  border: var(--border-size-base) solid currentColor !important;
}

.border-size-m {
  border: var(--border-size-m) solid currentColor !important;
}

.border-size-l {
  border: var(--border-size-l) solid currentColor !important;
}

.border-size-xl {
  border: var(--border-size-xl) solid currentColor !important;
}

.border-radius-none {
  border-radius: var(--border-radius-none) !important;
}

.border-radius-soft {
  border-radius: var(--border-radius-soft) !important;
}

.border-radius-rounded {
  border-radius: var(--border-radius-rounded) !important;
}

.border-radius-circle {
  border-radius: var(--border-radius-circle) !important;
}

.shadow-none {
  box-shadow: var(--shadow-none);
}

.shadow-xs {
  box-shadow: var(--shadow-xs);
}

.shadow-s {
  box-shadow: var(--shadow-s);
}

.shadow-m {
  box-shadow: var(--shadow-m);
}

.shadow-l {
  box-shadow: var(--shadow-l);
}

.shadow-xl {
  box-shadow: var(--shadow-xl);
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1400px) {
  .flex-wd-row {
    flex-direction: row !important;
  }
  .flex-wd-column {
    flex-direction: column !important;
  }
  .flex-wd-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-wd-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-wd-wrap {
    flex-wrap: wrap !important;
  }
  .flex-wd-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-wd-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-wd-start {
    justify-content: flex-start !important;
  }
  .justify-content-wd-end {
    justify-content: flex-end !important;
  }
  .justify-content-wd-center {
    justify-content: center !important;
  }
  .justify-content-wd-between {
    justify-content: space-between !important;
  }
  .justify-content-wd-around {
    justify-content: space-around !important;
  }
  .align-items-wd-start {
    align-items: flex-start !important;
  }
  .align-items-wd-end {
    align-items: flex-end !important;
  }
  .align-items-wd-center {
    align-items: center !important;
  }
  .align-items-wd-baseline {
    align-items: baseline !important;
  }
  .align-items-wd-stretch {
    align-items: stretch !important;
  }
  .align-content-wd-start {
    align-content: flex-start !important;
  }
  .align-content-wd-end {
    align-content: flex-end !important;
  }
  .align-content-wd-center {
    align-content: center !important;
  }
  .align-content-wd-between {
    align-content: space-between !important;
  }
  .align-content-wd-around {
    align-content: space-around !important;
  }
  .align-content-wd-stretch {
    align-content: stretch !important;
  }
  .align-self-wd-auto {
    align-self: auto !important;
  }
  .align-self-wd-start {
    align-self: flex-start !important;
  }
  .align-self-wd-end {
    align-self: flex-end !important;
  }
  .align-self-wd-center {
    align-self: center !important;
  }
  .align-self-wd-baseline {
    align-self: baseline !important;
  }
  .align-self-wd-stretch {
    align-self: stretch !important;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1400px) {
  .d-wd-none {
    display: none !important;
  }
  .d-wd-inline {
    display: inline !important;
  }
  .d-wd-inline-block {
    display: inline-block !important;
  }
  .d-wd-block {
    display: block !important;
  }
  .d-wd-table {
    display: table !important;
  }
  .d-wd-table-row {
    display: table-row !important;
  }
  .d-wd-table-cell {
    display: table-cell !important;
  }
  .d-wd-flex {
    display: flex !important;
  }
  .d-wd-inline-flex {
    display: inline-flex !important;
  }
}

.d-print-block {
  display: none !important;
}

@media print {
  .d-print-block {
    display: block !important;
  }
}

.d-print-inline {
  display: none !important;
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
}

.d-print-inline-block {
  display: none !important;
}

@media print {
  .d-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.container {
  width: 100%;
  padding-right: calc(var(--gutter-width) / 2);
  padding-left: calc(var(--gutter-width) / 2);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: calc(var(--gutter-width) / 2);
  padding-left: calc(var(--gutter-width) / 2);
  margin-right: auto;
  margin-left: auto;
}

.container-box {
  width: auto;
  padding-right: calc(var(--gutter-width) / 2);
  padding-left: calc(var(--gutter-width) / 2);
}

@media (min-width: 992px) {
  .container-box {
    margin-right: var(--container-box-margin);
    margin-left: var(--container-box-margin);
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--gutter-width) / -2);
  margin-left: calc(var(--gutter-width) / -2);
}

.row--sm {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(calc(var(--gutter-width) / 2) / -2);
  margin-left: calc(calc(var(--gutter-width) / 2) / -2);
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.grid-column, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-wd-1, .col-wd-2, .col-wd-3, .col-wd-4, .col-wd-5, .col-wd-6, .col-wd-7, .col-wd-8, .col-wd-9, .col-wd-10, .col-wd-11, .col-wd-12, .col-wd,
.col-wd-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: calc(var(--gutter-width) / 2);
  padding-left: calc(var(--gutter-width) / 2);
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-inline-start: 8.33333%;
}

.offset-2 {
  margin-inline-start: 16.66667%;
}

.offset-3 {
  margin-inline-start: 25%;
}

.offset-4 {
  margin-inline-start: 33.33333%;
}

.offset-5 {
  margin-inline-start: 41.66667%;
}

.offset-6 {
  margin-inline-start: 50%;
}

.offset-7 {
  margin-inline-start: 58.33333%;
}

.offset-8 {
  margin-inline-start: 66.66667%;
}

.offset-9 {
  margin-inline-start: 75%;
}

.offset-10 {
  margin-inline-start: 83.33333%;
}

.offset-11 {
  margin-inline-start: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-inline-start: 0;
  }
  .offset-sm-1 {
    margin-inline-start: 8.33333%;
  }
  .offset-sm-2 {
    margin-inline-start: 16.66667%;
  }
  .offset-sm-3 {
    margin-inline-start: 25%;
  }
  .offset-sm-4 {
    margin-inline-start: 33.33333%;
  }
  .offset-sm-5 {
    margin-inline-start: 41.66667%;
  }
  .offset-sm-6 {
    margin-inline-start: 50%;
  }
  .offset-sm-7 {
    margin-inline-start: 58.33333%;
  }
  .offset-sm-8 {
    margin-inline-start: 66.66667%;
  }
  .offset-sm-9 {
    margin-inline-start: 75%;
  }
  .offset-sm-10 {
    margin-inline-start: 83.33333%;
  }
  .offset-sm-11 {
    margin-inline-start: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-inline-start: 0;
  }
  .offset-md-1 {
    margin-inline-start: 8.33333%;
  }
  .offset-md-2 {
    margin-inline-start: 16.66667%;
  }
  .offset-md-3 {
    margin-inline-start: 25%;
  }
  .offset-md-4 {
    margin-inline-start: 33.33333%;
  }
  .offset-md-5 {
    margin-inline-start: 41.66667%;
  }
  .offset-md-6 {
    margin-inline-start: 50%;
  }
  .offset-md-7 {
    margin-inline-start: 58.33333%;
  }
  .offset-md-8 {
    margin-inline-start: 66.66667%;
  }
  .offset-md-9 {
    margin-inline-start: 75%;
  }
  .offset-md-10 {
    margin-inline-start: 83.33333%;
  }
  .offset-md-11 {
    margin-inline-start: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-inline-start: 0;
  }
  .offset-lg-1 {
    margin-inline-start: 8.33333%;
  }
  .offset-lg-2 {
    margin-inline-start: 16.66667%;
  }
  .offset-lg-3 {
    margin-inline-start: 25%;
  }
  .offset-lg-4 {
    margin-inline-start: 33.33333%;
  }
  .offset-lg-5 {
    margin-inline-start: 41.66667%;
  }
  .offset-lg-6 {
    margin-inline-start: 50%;
  }
  .offset-lg-7 {
    margin-inline-start: 58.33333%;
  }
  .offset-lg-8 {
    margin-inline-start: 66.66667%;
  }
  .offset-lg-9 {
    margin-inline-start: 75%;
  }
  .offset-lg-10 {
    margin-inline-start: 83.33333%;
  }
  .offset-lg-11 {
    margin-inline-start: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-inline-start: 0;
  }
  .offset-xl-1 {
    margin-inline-start: 8.33333%;
  }
  .offset-xl-2 {
    margin-inline-start: 16.66667%;
  }
  .offset-xl-3 {
    margin-inline-start: 25%;
  }
  .offset-xl-4 {
    margin-inline-start: 33.33333%;
  }
  .offset-xl-5 {
    margin-inline-start: 41.66667%;
  }
  .offset-xl-6 {
    margin-inline-start: 50%;
  }
  .offset-xl-7 {
    margin-inline-start: 58.33333%;
  }
  .offset-xl-8 {
    margin-inline-start: 66.66667%;
  }
  .offset-xl-9 {
    margin-inline-start: 75%;
  }
  .offset-xl-10 {
    margin-inline-start: 83.33333%;
  }
  .offset-xl-11 {
    margin-inline-start: 91.66667%;
  }
}

@media (min-width: 1400px) {
  .col-wd {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-wd-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-wd-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-wd-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-wd-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-wd-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-wd-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-wd-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-wd-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-wd-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-wd-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-wd-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-wd-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-wd-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-wd-first {
    order: -1;
  }
  .order-wd-1 {
    order: 1;
  }
  .order-wd-2 {
    order: 2;
  }
  .order-wd-3 {
    order: 3;
  }
  .order-wd-4 {
    order: 4;
  }
  .order-wd-5 {
    order: 5;
  }
  .order-wd-6 {
    order: 6;
  }
  .order-wd-7 {
    order: 7;
  }
  .order-wd-8 {
    order: 8;
  }
  .order-wd-9 {
    order: 9;
  }
  .order-wd-10 {
    order: 10;
  }
  .order-wd-11 {
    order: 11;
  }
  .order-wd-12 {
    order: 12;
  }
  .offset-wd-0 {
    margin-inline-start: 0;
  }
  .offset-wd-1 {
    margin-inline-start: 8.33333%;
  }
  .offset-wd-2 {
    margin-inline-start: 16.66667%;
  }
  .offset-wd-3 {
    margin-inline-start: 25%;
  }
  .offset-wd-4 {
    margin-inline-start: 33.33333%;
  }
  .offset-wd-5 {
    margin-inline-start: 41.66667%;
  }
  .offset-wd-6 {
    margin-inline-start: 50%;
  }
  .offset-wd-7 {
    margin-inline-start: 58.33333%;
  }
  .offset-wd-8 {
    margin-inline-start: 66.66667%;
  }
  .offset-wd-9 {
    margin-inline-start: 75%;
  }
  .offset-wd-10 {
    margin-inline-start: 83.33333%;
  }
  .offset-wd-11 {
    margin-inline-start: 91.66667%;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: none;
}

.form-control::placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.col-form-legend {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.form-control-plaintext {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc( 1.8125rem + 2px);
}

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control-lg:not([size]):not([multiple]) {
  height: calc( 2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-group > label {
  margin-bottom: 0.5rem;
  display: inline-flex;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
}

.form-check.disabled .form-check-label {
  color: #868e96;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}

.form-check-inline {
  display: inline-block;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-label {
  vertical-align: middle;
}

.valid-feedback {
  display: none;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-inline .form-check-label {
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .form-inline .custom-control-indicator {
    position: static;
    display: inline-block;
    margin-right: 0.25rem;
    vertical-align: text-bottom;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:focus, .btn:hover {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
  background-image: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #666e76;
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #111;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #111;
  background-color: #d39e00;
  border-color: #c69500;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #111;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not([disabled]):not(.disabled):active, .btn-light:not([disabled]):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #111;
  background-color: #dae0e5;
  border-color: #d3d9df;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not([disabled]):not(.disabled):active, .btn-dark:not([disabled]):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}

.btn-outline-secondary:not([disabled]):not(.disabled):active, .btn-outline-secondary:not([disabled]):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not([disabled]):not(.disabled):active, .btn-outline-success:not([disabled]):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not([disabled]):not(.disabled):active, .btn-outline-info:not([disabled]):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not([disabled]):not(.disabled):active, .btn-outline-warning:not([disabled]):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not([disabled]):not(.disabled):active, .btn-outline-danger:not([disabled]):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not([disabled]):not(.disabled):active, .btn-outline-light:not([disabled]):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not([disabled]):not(.disabled):active, .btn-outline-dark:not([disabled]):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #868e96;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.button {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  letter-spacing: 0.04em;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  padding: var(--space-s) var(--space-base);
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  vertical-align: middle;
  border: 2px solid;
  color: var(--color-primary);
  font-weight: 400;
  outline: none;
}

.button:focus {
  border: 1px solid var(--color-primary);
}

.button--light {
  background-color: var(--color-white-3);
  color: var(---color-black-0);
}

.button--light:hover {
  background-color: var(--color-primary);
  color: var(--color-neutral-0);
}

.button--primary {
  background-color: var(--color-primary);
  color: var(--color-neutral-0);
}

.button--primary:hover {
  background-color: var(--color-primary-hover);
  color: var(--color-neutral-0);
  transition: 0.3s;
}

.button--secondary {
  background-color: var(--color-secondary);
  color: var(--color-neutral-0);
}

.button--secondary:hover {
  background-color: var(--color-neutral-8);
  color: var(--color-secondary);
  transition: 0.3s;
}

.button--icon {
  background-color: var(--color-primary);
  color: var(--color-neutral-0);
}

.button--icon:hover {
  background-color: var(--color-primary);
  color: var(--color-neutral-0);
  transition: 0.3s;
}

.button--outline {
  background-color: var(--color-white-0);
  color: var(---color-black-0);
  border: 1px solid;
}

.button--outline:hover {
  background-color: var(--color-primary);
  color: var(--color-neutral-0);
  transition: 0.3s;
}

.button--raised {
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.button--rounded {
  border-radius: var(--border-radius-rounded);
}

.button--lg {
  padding-top: var(--space-m);
  padding-bottom: var(--space-m);
  padding-left: var(--space-l);
  padding-right: var(--space-l);
}

.button--block {
  width: 100%;
}

.button--transparent-background {
  background-color: transparent;
}

.material-icons {
  color: var(--color-neutral-0);
}

.main-header {
  padding-top: var(--space-m);
  padding-bottom: var(--space-m);
  background-color: var(--header-bg);
}

.main-header__container {
  display: flex;
  align-items: center;
}

@media (min-width: 576px) {
  .main-header__container {
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .main-header__burger-menu {
    display: none;
  }
}

.brand {
  --brand-width: 80px;
  --brand-height: 40px;
  --brand-width-lg: 120px;
  --brand-height-lg: 60px;
  width: var(--brand-width);
  height: var(--brand-height);
  fill: var(--color-primary);
}

.brand--lg {
  width: var(--brand-width-lg);
  height: var(--brand-height-lg);
}

.navbar, .main-footer__nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

@media (min-width: 768px) {
  .navbar, .main-footer__nav {
    display: flex;
    margin: 0 calc(-1 * var(--space-base));
  }
}

.navbar__item, .main-footer__nav-item {
  display: flex;
  position: relative;
}

.navbar__link, .main-footer__nav-link {
  color: var(--color-neutral-0);
  font-weight: var(--font-regular);
  transition: color 0.3s ease-in-out;
  padding: var(--space-xs) var(--space-m);
}

@media (min-width: 0) and (max-width: 991px) {
  .navbar__link, .main-footer__nav-link {
    display: flex;
    width: 100%;
    padding: var(--space-base) var(--space-s);
    border-bottom: 1px solid var(--color-neutral-3);
  }
}

.navbar__link:hover, .main-footer__nav-link:hover {
  color: var(--color-pink-1);
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .global-navigation {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    background: var(--color-shade-0);
    padding: var(--space-base);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform: scale(0.98);
    transition: all .3s ease-in-out;
  }
}

@media (min-width: 0) and (max-width: 991px) {
  .global-navigation--opened {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

.global-navigation__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-l);
}

@media (min-width: 768px) {
  .global-navigation__header {
    display: none;
  }
}

.navbar__group-button {
  display: flex;
}

@media only sreen and (min-width: 600px) {
  .navbar__group-button {
    margin-left: var(--space-xxl);
  }
}

@media (max-width: 600px) {
  .navbar__group-button {
    margin-top: var(--space-m);
  }
}

.navbar__item__register {
  border: 1px solid;
}

.ar-font {
  font-family: 'Tajawal', sans-serif;
}

.form-container {
  max-width: 400px;
  margin: auto;
}

.mdc-checkbox-wrapper {
  margin-left: -11px;
  margin-right: -11px;
}

.form__new-user {
  margin-left: auto;
}

.form__new-user:hover {
  text-decoration: none;
}

.form-fade {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation: fadeInUp 2s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.form-sep {
  position: relative;
  border-bottom: 1px solid #ccc;
  margin: 16px 0;
}

.form-sep > span {
  position: absolute;
  width: 60px;
  height: 60px;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.form-social {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.form-social__btn {
  width: 80%;
  border: 0;
  padding: 7px;
  cursor: pointer;
}

.form-social__btn--google {
  background-color: #d95232;
  color: #fff;
}

.main-footer {
  background-color: var(--footer-bg);
  padding: var(--space-base) 0 var(--space-l);
}

@media (min-width: 768px) {
  .main-footer__container {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 0) and (max-width: 991px) {
  .main-footer__nav {
    display: none;
  }
}

@media (min-width: 768px) {
  .main-footer__nav {
    margin: 0 calc(-1 * var(--space-s));
  }
}

.main-footer__nav-link {
  font-size: var(--font-size-s);
  font-weight: var(--font-regular);
  color: var(--color-black-0);
  padding-right: var(--space-s);
  padding-left: var(--space-s);
}

.main-footer__copy-rights {
  font-size: var(--font-size-s);
  font-weight: var(--font-regular);
  color: var(--color-black-1);
}

@media (min-width: 768px) {
  .main-footer__copy-rights {
    margin-top: var(--space-s);
    margin-bottom: var(--space-none);
  }
}

@media (min-width: 0) and (max-width: 991px) {
  .main-footer__start,
  .main-footer__end {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .main-footer__end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.icons-bar {
  display: flex;
}

.icon {
  display: inline-flex;
  padding: 8px;
  border-radius: 50%;
  background-color: var(--color-shade-0);
  transition: all 0.2s ease-in-out;
}

.icon > svg {
  fill: rgba(var(--color-shade-0), 0.54);
  width: 24px;
  height: 24px;
}

.icon--sm > svg {
  width: 16px;
  height: 16px;
}

.icon:hover {
  background-color: var(--color-neutral-6);
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (min-width: 576px) {
  .card {
    --image-height: 200px;
  }
}

.card--border {
  border: var(--border-size-s) solid var(--color-neutral-5);
}

.card--border-soft {
  border-radius: var(--border-radius-soft);
}

.card--border-soft .card__image img {
  border-top-left-radius: var(--border-radius-soft);
  border-top-right-radius: var(--border-radius-soft);
}

.card--elevation {
  box-shadow: var(--shadow-s);
}

.card__image {
  height: var(--image-height);
}

.card__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card__content {
  padding: var(--space-base);
}

.card__title {
  font-size: 1.3em;
  color: var(--color-neutral-10);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card__title a {
  text-decoration: none;
  color: var(--color-violet-darkest-1);
  transition: color .3s ease-in-out;
}

.card__title a:hover {
  color: var(--color-neutral-10);
}

.card__brief--lines-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card__actions {
  padding-left: var(--space-base);
  padding-right: var(--space-base);
  padding-bottom: var(--space-base);
  margin-top: auto;
}

.card--horizontal {
  flex-direction: row;
}

.card--horizontal .card__image {
  flex: 0 0 40%;
}

.card--horizontal .card__actions {
  padding: var(--space-l) var(--space-none) var(--space-none) var(--space-none);
}

.card--reverse {
  flex-direction: row-reverse;
}

.card__image--absolute {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  bottom: 24px;
  left: -5px;
  max-width: 305px;
  text-align: start;
  min-width: 305px;
}

.card__title .color-white {
  color: var(--color-neutral-0);
}

.card .card__image--350 {
  height: 350px;
}

@media only screen and (max-width: 480px) {
  .bottom-spacing {
    margin-bottom: 20px;
  }
}

.card__icon::before {
  content: '';
  display: block;
  margin-bottom: var(--space-s);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: linear-gradient(90deg, #6fcbdc 0.23%, #ec0f68 23%, #bf3e96 49%, #ed2529 75%, #f16722 94%);
}

.page-section {
  padding: var(--space-xl) 0;
  text-align: center;
}

.page-section__title {
  font-size: var(--font-size-xl);
  color: var(--color-primary);
  margin-bottom: var(--space-s);
}

.page-section__decription {
  color: var(--color-gray-darkest-1);
  margin-bottom: var(--space-xxl);
}

.card__image--rounded {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  margin: auto;
  position: relative;
}

.card__image--rounded svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.h-100 {
  height: 100%;
}

.card__title .color-purple {
  color: var(--color-primary);
}

.card__title .color-pink2 {
  color: var(--color-pink-1);
}

.card__title .font-size-xxl {
  font-size: var(--font-size-xxl);
}

.text-center {
  text-align: center;
}

.m-auto {
  margin: auto;
}

.card .padding-left-none {
  padding-left: var(--space-none);
}

@media only screen and (max-width: 768px) {
  .card--horizontal {
    display: block;
  }
}

.card__image img {
  min-height: 660px;
}

.card-background-right {
  background-image: url(/src/images/Group728.jpg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-color: var(--color-gray-light);
}

.text-start {
  text-align: start;
}

@media only screen and (max-width: 1024px) {
  .card-background-right {
    background-image: none;
  }
}

body .card-image--left img {
  min-height: auto;
  width: 100%;
}

body .card__content--right {
  text-align: start;
  padding-top: var(--space-xl);
}

body .card-image--right img {
  min-height: auto;
  width: 100%;
}

body .card__content--left {
  text-align: end;
  padding-top: var(--space-xl);
}

@media only screen and (min-width: 769px) {
  body .card__content--right {
    text-align: start;
    padding-left: var(--space-xxl);
    padding-top: var(--space-none);
  }
  body .card__content--left {
    text-align: end;
    padding-right: var(--space-xxl);
    padding-top: var(--space-none);
  }
}

.hero-section {
  background-image: url(/src/images/banner.jpg);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: var(--space-xxl);
}

.hero-section__content {
  margin-top: var(--space-xxl);
}

.hero-section__title {
  color: var(--color-neutral-0);
  font-size: var(--font-size-xxl);
}

.hero-section__description {
  color: var(--color-neutral-0);
  font-size: var(--font-size-h5);
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-m);
}

.bottom-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.mission-section {
  background-image: url(/src/images/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: var(--space-xxl) 0;
}

.mission-section__content {
  font-size: var(--font-size-h1);
  color: var(--color-neutral-0);
  text-align: center;
}

.campagin-section {
  background-image: url(/src/images/bg-2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: var(--space-xxl) 0;
  text-align: center;
}

.campagin-section__title {
  color: var(--color-neutral-0);
  font-size: var(--font-size-xxl);
}

.campagin-section__description {
  color: var(--color-neutral-0);
  font-size: var(--font-size-l);
}

.hero-section--about-background {
  background-image: url(/src/images/about.png);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: var(--space-xxl);
}

.hero-section__title--center {
  text-align: center;
  padding-bottom: var(--space-xxl);
  padding-top: var(--space-xxl);
  color: var(--color-pink-1);
}

.white-title-section {
  color: #fff;
}

.accordion-section {
  width: 100%;
  margin-top: 20px;
}

.accordion-section .accordion__item {
  transition: 0.4s;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 25px 0 25px 30px;
}

.accordion-section .accordion__item.active {
  background: #e9f4fd;
}

.accordion-section .accordion__item .accordion__item--title {
  background: transparent;
  margin: 0;
  text-align: left;
  cursor: pointer;
  color: #505050;
  position: relative;
}

.accordion-section .accordion__item .accordion__item--title.active {
  color: #92298d;
}

.accordion-section .accordion__item .accordion__item--title.active::after {
  content: "\f068";
}

.accordion-section .accordion__item .accordion__item--title::after {
  content: "\f067";
  font-family: "fontAwesome";
  position: absolute;
  right: 5px;
}

.accordion-section .accordion__item .accordion__item--description {
  transition: 0.4s;
  text-align: left;
  display: none;
  overflow: hidden;
  margin-top: 30px;
  line-height: 30px;
}

.terms-section-title {
  color: #D3157A;
}

.terms-main-title {
  font-size: var(--font-size-xxl);
  color: var(--color-primary);
}

.terms-section {
  text-align: left;
}

.terms-section h2 {
  color: #1C0F5F;
  font-size: var(--font-size-h2);
  font-weight: 500;
}

.terms-section h3 {
  color: #505050;
  font-weight: 700;
  font-size: var(--font-size-h5);
}

.terms-section p {
  font-size: var(--font-size-h5);
  color: #505050;
  line-height: var(--font-size-mx);
  margin-bottom: 30px;
}

.terms-section hr {
  border-top: 1px solid #eee;
}

.terms-section ul {
  font-size: var(--font-size-h5);
  color: #505050;
  line-height: var(--font-size-mx);
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

.contact-section {
  text-align: center;
  width: 100%;
}

.contact-section .main-title {
  font-size: var(--font-size-mx);
  color: #1c0f5f;
  font-weight: 500;
  margin: var(--space-l);
}

.contact-section .contact-details p {
  color: #505050;
  font-size: var(--font-size-h5);
  margin: 0;
  line-height: 40px;
}

.contact-section + hr {
  border-top: 1px solid #eee;
  width: 100%;
  margin: var(--space-xl);
}

.contact-form {
  width: 65%;
  text-align: center;
  margin: auto;
}

.contact-form p {
  font-size: var(--font-size-h2);
  line-height: var(--font-size-xl);
  color: #505050;
  margin-bottom: var(--space-xl);
}

.contact-form .form-field {
  margin-bottom: var(--space-l);
}

.contact-form .form-field input, .contact-form .form-field textarea {
  border: 0;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  line-height: var(--font-size-xl);
  outline: none;
}

@media only screen and (max-width: 600px) {
  .contact-form {
    width: 90%;
  }
}

.header {
  padding: 1.8rem 0;
  z-index: 100;
  position: absolute;
  width: 100%;
}

.header h1 {
  margin-bottom: 0;
}

@media (min-width: 0) and (max-width: 767px) {
  .header {
    background-color: #fff;
    padding: 5px 0;
  }
}

.header .change-language {
  margin-left: 25px;
}

@media (min-width: 768px) {
  .header--absolute {
    position: absolute;
    width: 100%;
    z-index: 10;
  }
}

.header--relative {
  position: relative;
}

.header__button {
  border-width: 2px;
}

.header__search-box {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.header__search-txtbox {
  background: transparent;
  border: 0;
  outline: none;
  color: #fff;
  margin-left: 5px;
  width: 70px;
  transition: all 0.3s ease-in-out 0s;
}

.header__search-txtbox:focus {
  width: 200px;
}

.header__search-txtbox::placeholder {
  color: rgba(255, 255, 255, 0.87);
}

.responsive-menu {
  display: flex;
  flex-direction: inherit;
  align-items: center;
}

@media (min-width: 0) and (max-width: 767px) {
  .responsive-menu {
    display: none;
    flex-direction: column-reverse;
  }
}

@media (min-width: 0) and (max-width: 767px) {
  .responsive-menu > nav {
    margin-bottom: auto;
  }
}

.hamburger-menu {
  position: absolute;
  right: auto;
  top: auto;
  z-index: 100;
  width: 20px;
  height: auto;
  border-radius: 50% 50% 50% 50%;
  padding: 10px;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  display: none;
}

@media (min-width: 0) and (max-width: 767px) {
  .hamburger-menu {
    display: block;
  }
}

.hamburger-menu > span {
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  height: 2px;
  background: #000;
  display: block;
  transform-origin: center;
  transition: 0.5s ease-in-out;
}

@media (min-width: 768px) {
  .hamburger-menu > span {
    width: 30px;
    top: 150px;
    left: 50px;
  }
}

.hamburger-menu > span:after, .hamburger-menu > span:before {
  transition: all 0.3s ease-in-out 0s;
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: #000;
  transition: 0.5s ease-in-out;
}

.hamburger-menu > span:before {
  top: -6px;
}

@media (min-width: 768px) {
  .hamburger-menu > span:before {
    top: -10px;
  }
}

.hamburger-menu > span:after {
  bottom: -6px;
}

@media (min-width: 768px) {
  .hamburger-menu > span:after {
    bottom: -10px;
  }
}

.hamburger-menu--dark > span {
  background-color: #000;
}

.hamburger-menu--dark > span:after, .hamburger-menu--dark > span:before {
  background-color: #000;
}

.hamburger-menu--open {
  box-shadow: 0 0 0 100vw #fff, 0 0 0 100vh #fff;
  background: #fff;
  border-radius: 0;
  position: fixed;
}

.hamburger-menu--open > span {
  transform: rotate(45deg);
  background: #000;
}

.hamburger-menu--open > span:after {
  transform: rotate(90deg);
  bottom: 0;
  background: #000;
}

.hamburger-menu--open > span:before {
  transform: rotate(90deg);
  top: 0;
  background: #000;
}

.responsive-menu--open {
  display: flex;
  position: fixed;
  top: 100px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  z-index: 100;
  min-height: calc(100vh - 200px);
}

.responsive-menu--open .navbar, .responsive-menu--open .main-footer__nav {
  flex-direction: column;
}

.responsive-menu--open .navbar__item, .responsive-menu--open .main-footer__nav-item {
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  display: block;
  font-size: 1.9em;
  padding: 5px 25px;
}

@media (min-width: 0) and (max-width: 991px) {
  .responsive-menu--open .navbar__item, .responsive-menu--open .main-footer__nav-item {
    text-align: left;
  }
}

.header--fixed {
  position: fixed;
  background-color: #fff;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
  z-index: 20;
}

@media (min-width: 768px) {
  .header--fixed {
    padding: 10px;
    font-size: 14px;
  }
}

.header--fixed .container {
  max-width: 1500px;
  transition: all 0.5s ease-in-out 0s;
}

.header--fixed .brand {
  width: 120px;
}

.header--fixed .brand__text {
  fill: #424242;
}

.header--fixed .navbar__item:not(.navbar__item--current), .header--fixed .main-footer__nav-item:not(.navbar__item--current) {
  color: rgba(0, 0, 0, 0.54);
}

.Lang-btn {
  color: #fff;
  padding: 0 25px;
  position: relative;
}

.Lang-btn::after {
  content: "";
  position: absolute;
  left: 20px;
  right: 100%;
  height: 3px;
  background: #ffc400;
  bottom: -10px;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .Lang-btn:hover {
    text-decoration: none;
  }
  .Lang-btn:hover::after {
    right: 20px;
  }
}

.body--relative-header .header {
  position: relative;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.body--relative-header .brand {
  width: 130px;
}

.body--relative-header .brand__emblem {
  fill: #37a2dc;
}

.body--relative-header .brand__text {
  fill: #424242;
}

.body--hide-menu .hamburger-menu,
.body--hide-menu .responsive-menu {
  display: none;
}

@media (min-width: 768px) {
  .header--scrolling {
    background-color: #fff;
    padding: .5rem 0;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
    z-index: 100;
    position: fixed;
    transform: translate(0, -100%);
    transition: all 0.2s ease-in-out;
  }
  .header--scrolling .container {
    max-width: 95%;
  }
  .header--scrolling .brand {
    width: 140px;
  }
  .header--scrolling .brand__text {
    fill: #424242;
  }
  .header--scrolling .navbar__item, .header--scrolling .main-footer__nav-item {
    color: rgba(0, 0, 0, 0.87);
  }
  .header--scrolling .navbar, .header--scrolling .main-footer__nav {
    font-size: 14px;
  }
}

.body--scrolling-down .header {
  transform: translate(0, -64px);
}

.body--scrolling-down .price__table--fixed {
  transform: translate(0, 0);
}

.body--scrolling-up .header {
  transform: translate(0, 0);
}

@media (min-width: 768px) {
  .body--scrolling-up .price__table--fixed {
    transform: translate(0, 64px);
  }
}

body .main-header--transparent-background {
  background-color: transparent;
}

.border-none {
  border: none;
}

.border--primary {
  border-color: var(--color-primary);
}

.navbar__item__login {
  margin-right: var(--space-base);
}

.burger-menu--light {
  color: var(--color-neutral-0);
}

.gallery-section {
  padding: var(--space-xxl) 0;
  text-align: center;
}

.gallery-section__title {
  font-size: var(--font-size-xl);
  color: var(--color-primary);
}

.gallery-section__description {
  color: var(--color-gray-darkest-1);
  margin-bottom: var(--space-l);
}

.padding-left-none {
  padding-left: var(--space-none);
}

.gallery-grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1.1fr 1.1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 30px 30px;
  grid-template-areas: "image-1 image-2 image-2 image-2 image-2" "image-1 image-3 image-3 image-4 image-4" "image-5 image-5 image-5 image-4 image-4" "image-5 image-5 image-5 image-6 image-6";
}

.image-1 {
  grid-area: image-1;
}

.image-2 {
  grid-area: image-2;
}

.image-3 {
  grid-area: image-3;
}

.image-4 {
  grid-area: image-4;
}

.image-5 {
  grid-area: image-5;
}

.image-6 {
  grid-area: image-6;
}

.image-1 img {
  width: 100%;
  height: 100%;
}

.image-2 img {
  width: 100%;
  height: 100%;
}

.image-3 img {
  width: 100%;
  height: 100%;
}

.image-4 img {
  width: 100%;
  height: 100%;
}

.image-5 img {
  width: 100%;
  height: 100%;
}

.image-6 img {
  width: 100%;
  height: 100%;
}

body .home-blockquotes {
  width: 90%;
  position: relative !important;
  margin: calc(20vh/2) auto;
  overflow: hidden;
  border: 2px solid var(--color-neutral-0);
  border-radius: var(--border-radius-soft);
  box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
  background-color: var(--color-neutral-0);
}

.slides {
  width: 100%;
  height: 100%;
  display: flex;
}

.slide {
  min-width: 100%;
  height: 100%;
  position: relative;
  padding: var(--space-l);
}

.slider-section {
  padding: var(--space-xxl);
  text-align: left;
}

.slider-section__title {
  color: var(--color-primary);
  font-size: var(--font-size-xl);
}

.left-quote {
  position: absolute;
  left: 0;
  top: -50px;
}

.right-quote {
  position: absolute;
  right: 0;
  bottom: -50px;
}

.position-relative {
  position: relative;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  padding: var(--space-l);
  font-size: 18px;
  background: #fff;
}

.swiper-slide p:first-child {
  color: var(--color-neutral-11);
}

.swiper-pagination-bullet {
  background-color: transparent;
  width: 10px;
  height: 10px;
  margin: var(--space-xs);
  border: 1px solid var(--color-blue-darkest-1);
}

body .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.swiper-pagination {
  position: absolute;
  left: 0;
  right: 0;
}

.main-footer__nav-link {
  color: var(--color-gray-darkest-4);
}

.footer--brand {
  width: 126px;
  height: 55px;
  margin-bottom: var(--space-l);
}

.main-footer__nav--end {
  justify-content: flex-end;
}

@media only screen and (min-width: 600px) {
  .main-footer__bottom-text {
    display: flex;
    justify-content: space-between;
  }
}

.main-footer__bottom-text {
  text-align: center;
}

hr {
  border: 1px solid var(--color-light-gray);
}

.main-footer__container {
  align-items: center;
}

.main-footer__copy-rights {
  color: var(--color-neutral-11);
}
