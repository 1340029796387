.hero-section {
  background-image: url(/src/images/banner.jpg);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: var(--space-xxl);
}


.hero-section__content {
  margin-top: var(--space-xxl);
}

.hero-section__title {
  color: var(--color-neutral-0);
  font-size: var(--font-size-xxl);
}

.hero-section__description {
  color: var(--color-neutral-0);
  font-size: var(--font-size-h5);
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-m);
}

.bottom-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.mission-section {
  background-image: url(/src/images/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: var(--space-xxl) 0;
}

.mission-section__content {
  font-size: var(--font-size-h1);
  color: var(--color-neutral-0);
  text-align: center;
}


.campagin-section {
  background-image: url(/src/images/bg-2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: var(--space-xxl) 0;
  text-align: center;
}

.campagin-section__title {
  color: var(--color-neutral-0);
  font-size: var(--font-size-xxl);
}

.campagin-section__description {
  color: var(--color-neutral-0);
  font-size: var(--font-size-l);
}

.hero-section--about-background {
  background-image: url(/src/images/about.png);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: var(--space-xxl);
}

.hero-section__title--center {
  text-align: center;
  padding-bottom: var(--space-xxl);
  padding-top: var(--space-xxl);
  color: var(--color-pink-1);
}